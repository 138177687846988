import includes from 'lodash/includes';
import cloneDeep from 'lodash/cloneDeep';


const parse = (text: string, reviver?: any, pureJson: boolean = false): any => {

    if (pureJson) return JSON.parse(text);

    function wrappedReviver(key: any, value: any): any {
        if (value == '__NaN__') return NaN;
        if (!reviver) return value;
        // @ts-ignore
        return reviver.call(this, key, value);
    }

    return JSON.parse(text.replace(/": NaN/g, '": "__NaN__"'), wrappedReviver);
};

const stringify = (value: any, replacer?: any, space?: any) => {

    function wrappedReplacer(key: any, value: any) {
        if (Number.isNaN(value)) return '__NaN__';
        if (!replacer) return value;
        // @ts-ignore
        return replacer.call(this, key, value);
    }

    if (value === undefined) return '';
    return JSON.stringify(value, wrappedReplacer, space).replace(/"__NaN__"/g, 'NaN');
};

const vueProps = ['dep', 'vmCount'];
function stripVueProps(obj: any|{[key: string]: any}): any {
    if (typeof obj !== 'object') return obj;

    const clonedObj = cloneDeep(obj);
    for (let prop in clonedObj) {
        if (clonedObj.hasOwnProperty(prop)) {
            // Remove it if it is a Vue prop
            if (includes(vueProps, prop)) {
                delete clonedObj[prop];

            // Recursively remove Vue props
            } else if (typeof clonedObj[prop] == 'object') {
                stripVueProps(clonedObj[prop]);
            }
        }
    }
    return clonedObj;
}

export {parse, stringify, stripVueProps};
