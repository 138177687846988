<template>
    <div v-resize="_onResize">
        <div ref="curveWrapper" :style="{height: height+'px'}" class="mb-4">
            <svg
                height="100%" width="100%" id="curve" :class="{pointer: hasClosest || inPtMove, dragging: inPtMove}"
                @mouseout="_onMouseOut"
                @mousemove="_onMouseMove($event.clientX, $event.clientY)"
                @mousedown="_onMouseDown"
                @mouseup="_onMouseUp"
                ref="svg"
            >
                <!-- Axis lines -->
                <line :x1="mar" :y1="mar" :x2="mar" :y2="wh-mar" />
                <line :x1="mar" :y1="wh-mar" :x2="ww-mar" :y2="wh-mar" />

                <!-- y-axis labels -->
                <line :x1="(1-tl)*mar" :y1="mar" :x2="mar" :y2="mar" />
                <text :x=".25*mar" :y="mar">1</text>
                <line :x1="(1-tl)*mar" :y1="wh-mar" :x2="mar" :y2="wh-mar" />
                <text :x=".25*mar" :y="wh-mar">0</text>

                <!-- x-axis labels -->
                <line :x1="mar" :y1="wh-mar" :x2="mar" :y2="wh-(1-tl)*mar" />
                <text :x="mar" :y="wh-.25*mar">{{ pathBounds.xMin }}</text>
                <line :x1="ww-mar" :y1="wh-mar" :x2="ww-mar" :y2="wh-(1-tl)*mar" />
                <text :x="ww-mar" :y="wh-.25*mar">{{ pathBounds.xMax }}</text>

                <!-- Axis titles -->
                <text :x=".5*mar" :y="mar+.5*graphHeight" :transform="`rotate(-90 ${.5*mar} ${mar+.5*graphHeight})`">Utility</text>
                <text :x="mar+.5*graphWidth" :y="wh-.5*mar">Attribute Content</text>

                <!-- Utility curve -->
                <path :d="displayPath" stroke="red" stroke-width="2px" fill="none" />

                <!-- Curve control points -->
                <g v-if="showControlPoints">
                    <line
                        v-for="(pt, idx) in curveControlPoints"
                        v-if="idx % 2 === 0"
                        :x1="pt.x" :y1="pt.y"
                        :x2="curveControlPoints[idx+1].x" :y2="curveControlPoints[idx+1].y"
                        class="guide"
                    />
                    <circle v-for="(pt, idx) in curveControlPoints" v-if="idx % 2 === 1" r="3" :cx="pt.x" :cy="pt.y" fill="green" />
                </g>

                <!-- Control points -->
                <circle v-if="showControlPoints" v-for="pt in controlPoints" r="3" :cx="pt.x" :cy="pt.y" fill="red" />

                <!-- Sample point -->
                <g v-if="displaySample" style="pointer-events: none">
                    <line :x1="mar" :y1="samplePoint[1]" :x2="samplePoint[0]" :y2="samplePoint[1]" stroke-dasharray="4" />
                    <line :x1="samplePoint[0]" :y1="samplePoint[1]" :x2="samplePoint[0]" :y2="wh-mar" stroke-dasharray="4" />
                    <circle r="5" :cx="samplePoint[0]" :cy="samplePoint[1]" fill="blue" />
                    <text :x=".5*ww" :y=".5*mar">{{ samplePointText }}</text>
                </g>
            </svg>
        </div>

        <h3 v-if="showControlPoints" style="position: relative">
            Control Points
            <span style="position: absolute; top: 0; right: 0;" v-if="showResetControlPoints">
                <v-btn @click="_resetClick" icon elevation="2" title="Reset utility curve"
                    ><v-icon>{{ mdiUndoVariant }}</v-icon></v-btn>
            </span>
        </h3>
        <v-simple-table dense style="display: inline-block" v-if="showControlPoints">
            <thead>
                <tr>
                    <th>Attribute Content</th>
                    <th>Utility</th>
                    <th>Type</th>
                    <th v-if="hasControlPointsCol">Control Points</th>
                    <th v-if="editable">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, idx) in items" :key="idx" :class="{'light-blue lighten-5': moveIdxHighlight === idx}">
                    <td>
                        <v-text-field
                            v-if="editable"
                            v-model="item.attr"
                            @blur="_setNumCmdProp(idx, 'x', item.attr)"
                            :rules="[rules.required, rules.float]"
                            style="width: 100px"
                        />
                        <span v-else>{{ item.attr }}</span>
                    </td>
                    <td>
                        <span v-if="!editable || !item.utilEditable">{{ item.util }}</span>
                        <v-text-field
                            v-else
                            v-model="item.util"
                            :disabled="!editable"
                            @blur="_setUtil(idx, item.util)"
                            :rules="[rules.required, rules.float]"
                            style="width: 50px"
                        />
                    </td>
                    <td>
                        <span v-if="!editable || !item.typeEditable">{{ item.typeTitle }}</span>
                        <v-select
                            v-else
                            v-model="item.type"
                            :items="typeValues"
                            :disabled="!editable"
                            @change="_setType(idx, item.type)"
                        />
                    </td>
                    <td v-if="hasControlPointsCol" style="vertical-align: middle">
                        <span v-if="!editable">{{ item.ctrlPtText }}</span>
                        <div v-else v-for="ptIdx in item.nCtrl" style="white-space: nowrap">
                            {{ ptIdx }}:
                            <v-text-field
                                dense hide-details="auto"
                                v-model="item.ctrlPts[ptIdx-1].x"
                                @blur="_setCtrlPtCoord(idx, ptIdx-1, false, item.ctrlPts[ptIdx-1].x)"
                                :rules="[rules.required, rules.float]"
                                style="width: 50px; display: inline-block"
                            />,
                            <v-text-field
                                dense hide-details="auto"
                                v-model="item.ctrlPts[ptIdx-1].y"
                                @blur="_setCtrlPtCoord(idx, ptIdx-1, true, item.ctrlPts[ptIdx-1].y)"
                                :rules="[rules.required, rules.float]"
                                style="width: 50px; display: inline-block"
                            />
                        </div>
                    </td>
                    <td v-if="editable">
                        <v-btn @click.stop="_addCmdAfter(idx)" icon v-if="item.canAdd" title="Add new control point"
                            ><v-icon>{{ mdiPlus }}</v-icon></v-btn>
                        <v-btn @click.stop="_deleteCmd(idx)" icon v-if="item.deletable" title="Delete control point"
                            ><v-icon>{{ mdiTrashCan }}</v-icon></v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
    import Vue from "vue";
    import {
        parsePath, renderCommands, parseBounds, getBounds, defaultPath, getControlPoints, translateScale,
        interpolateCommands, fmt, getNrDigits, cmdTypes, typeTitles, setCommandType, roundUtil, getCurveControlPoints,
    } from "@/comp/utility-curve";
    import {calcBounds, calcUtility} from "@/model/utility";
    import {dispatcher} from "@/main";

    import {mdiTrashCan, mdiPlus, mdiUndoVariant} from '@mdi/js';
    import {SVGPathData} from "svg-pathdata";

    import map from 'lodash/map';
    import some from 'lodash/some';
    import join from 'lodash/join';
    import filter from 'lodash/filter';
    import cloneDeep from 'lodash/cloneDeep';

    export default Vue.extend({
        name: "utility-curve",
        props: {
            value: {required: true},
            bounds: {required: false},
            editable: {default: true},
            showControlPoints: {default: true},
            showResetControlPoints: {default: false},
            height: {default: 300},
        },
        data: () => ({
            cmd: cloneDeep(defaultPath),
            mdiTrashCan, mdiPlus, mdiUndoVariant,

            ww: 300,  // Wrapper width
            wh: 300,  // Wrapper height
            mar: 40,  // Axis margins
            tl: .25,  // Tick length

            curveSampleX: null,
            mouseX: null,
            mouseY: null,

            rules: {
                required: (value) => value !== '' || 'Required',
                float: (value) => !value || !isNaN(parseFloat(value)) || 'Must be a numeric value',
            },
            typeValues: map(cmdTypes.slice(1), (key) => ({ text: typeTitles[key], value: key })),

            deltaDrag: .05,
            inPtMove: false,
            movingPt: null,
            movingDeltaX: null,
            movingDeltaY: null,
            movingCmd: null,
            movingFixUtil: false,
        }),
        watch: {
            value(value) {
                this._newValue(value);
            },
        },
        computed: {
            pathBounds() {
                if (this.bounds !== undefined && this.bounds !== null) return parseBounds(this.bounds, true);
                if (this.cmd.length) return getBounds(this.cmd, true);
                return { xMin: 0, xMax: 1, yMin: 0, yMax: 1 };
            },
            shownCmd() {
                return (this.inPtMove) ? this.movingCmd: this.cmd;
            },
            translatedCmd() {
                return translateScale(this.shownCmd, this.pathBounds, {
                    xMin: this.mar,
                    xMax: this.ww-this.mar,
                    yMin: this.wh-this.mar,
                    yMax: this.mar,
                });
            },
            graphWidth() {
                return this.ww - 2*this.mar;
            },
            graphHeight() {
                return this.wh - 2*this.mar;
            },
            displayPath() {
                return renderCommands(this.translatedCmd);
            },
            controlPoints() {
                return getControlPoints(this.translatedCmd);
            },
            curveControlPoints() {
                return getCurveControlPoints(this.translatedCmd);
            },
            items() {
                const n = this.shownCmd.length;
                return map(this.shownCmd, (cmd, idx) => {
                    const editable = idx > 0 && idx < (n-1);
                    const typeTitle = typeTitles[cmd.type] || 'UNKNOWN';

                    let nCtrl = 0;
                    if (cmd.type === SVGPathData.QUAD_TO) {
                        nCtrl = 1;
                    } else if (cmd.type === SVGPathData.CURVE_TO) {
                        nCtrl = 2;
                    }
                    const ctrlPts = [];
                    for (let i = 0; i < nCtrl; i++) {
                        ctrlPts.push({ x: cmd['x'+(i+1)], y: cmd['y'+(i+1)] });
                    }

                    const ctrlPtText = join(map(ctrlPts, ({x, y}, idx) => `${idx+1}: (${x}, ${y})`), ', ');

                    return {
                        attr: cmd.x,
                        util: cmd.y,
                        utilEditable: editable,
                        type: cmd.type,
                        typeTitle,
                        typeEditable: idx > 0,
                        nCtrl,
                        ctrlPts,
                        ctrlPtText,
                        canAdd: idx < (n-1),
                        deletable: editable,
                    };
                });
            },

            displaySample() {
                return this.curveSampleX !== null;
            },
            renderedPath() {
                return renderCommands(this.cmd);
            },
            renderedBounds() {
                return calcBounds(this.renderedPath);
            },
            sampleUtil() {
                if (!this.displaySample) return 0;
                const [bounds, boundsU0] = this.renderedBounds;
                return calcUtility(this.renderedPath, this.curveSampleX, bounds, boundsU0);
            },
            samplePoint() {
                if (!this.displaySample) return [0, 0];
                const x = this.curveSampleX;
                const y = this.sampleUtil;

                const bounds = this.pathBounds;
                return [
                    this.mar+((x-bounds.xMin)/(bounds.xMax-bounds.xMin))*this.graphWidth,
                    this.wh-this.mar-((y-bounds.yMin)/(bounds.yMax-bounds.yMin))*this.graphHeight,
                ];
            },
            samplePointText() {
                const x = this.curveSampleX;
                const y = this.sampleUtil;
                const bounds = this.pathBounds;

                const xText = fmt(x, getNrDigits(bounds));
                const yText = fmt(y, 2);
                return `Attr ${xText} -> utility ${yText}`;
            },
            hasControlPointsCol() {
                return some(map(this.items, (item) => item.nCtrl > 0));
            },
            boundsRangeX() {
                const bounds = this.pathBounds;
                return bounds.xMax-bounds.xMin;
            },
            boundsRangeY() {
                const bounds = this.pathBounds;
                return bounds.yMax-bounds.yMin;
            },
            closestPoint() {
                if (!this.editable) return;
                const mouseX = this.mouseX;
                const mouseY = this.mouseY;
                if (mouseX === null) return null;
                const boundsRangeX = this.boundsRangeX;
                const boundsRangeY = this.boundsRangeY;
                const deltaDrag = this.deltaDrag;

                let closest = null;
                let dist = null;

                function calcDist(pt) {
                    const ptDist = Math.sqrt(((pt.x-mouseX)/boundsRangeX)**2 + ((pt.y-mouseY)/boundsRangeY)**2);
                    if (ptDist > deltaDrag) return;
                    if (dist === null || ptDist < dist) {
                        dist = ptDist;
                        closest = pt;
                    }
                }

                const items = this.items;
                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    calcDist({ idx: i, x: item.attr, y: item.util });

                    // Control points
                    for (let j = 0; j < item.ctrlPts.length; j++) {
                        calcDist({ idx: i, ctrlIdx: j, x: item.ctrlPts[j].x, y: item.ctrlPts[j].y});
                    }
                }
                return closest;
            },
            hasClosest() {
                return this.closestPoint !== null;
            },
            moveIdxHighlight() {
                if (!this.hasClosest) return null;
                return this.closestPoint.idx;
            },
        },
        methods: {
            _newValue(value) {
                if (!value) {
                    this.cmd = cloneDeep(defaultPath);
                    this._input(this.cmd);
                } else {
                    this.cmd = parsePath(value);
                }
            },
            _input(cmd) {
                if (!this.editable) return;
                this.$emit('input', renderCommands(cmd));
            },
            _resetClick() {
                this.$emit('reset');
            },

            _onResize() {
                const wrapper = this.$refs.curveWrapper;
                this.wh = wrapper.clientHeight;
                this.ww = wrapper.clientWidth;
            },

            _setUtil(idx, value) {
                this._setNumCmdProp(idx, 'y', value, roundUtil);
            },
            _setType(idx, type) {
                if (!this.editable) return;
                const updatedCmd = setCommandType(this.cmd, idx, type);
                this._input(updatedCmd);
            },
            _setCtrlPtCoord(idx, ptIdx, isY, value) {
                const key = ((isY) ? 'y': 'x')+(ptIdx+1);
                this._setNumCmdProp(idx, key, value, (isY) ? ((val) => roundUtil(val, false)): undefined);
            },
            _setNumCmdProp(idx, key, value, callback) {
                if (!this.editable) return;

                let val = parseFloat(value);
                if (!value || isNaN(val)) return;
                if (callback) val = callback(val);
                this._setCmdProp(idx, key, val);
            },
            _setCmdProp(idx, key, value) {
                const updatedCmd = cloneDeep(this.cmd);
                updatedCmd[idx][key] = value;
                this._input(updatedCmd);
            },
            _deleteCmd(idx) {
                this._input(filter(this.cmd, (_, i) => i !== idx));
            },
            _addCmdAfter(idx) {
                if (!this.editable) return;
                const newCommand = interpolateCommands(this.cmd[idx], this.cmd[idx+1]);

                const newCommands = cloneDeep(this.cmd);
                newCommands.splice(idx+1, 0, newCommand);
                this._input(newCommands);
            },

            _onMouseMove(x, y) {
                const bounds = this.pathBounds;
                const rect = this.$refs.svg.getBoundingClientRect();

                let relX = x - rect.x;
                relX = (relX - this.mar) / this.graphWidth;
                this.mouseX = bounds.xMin + relX * (bounds.xMax - bounds.xMin);

                let relY = y - rect.y;
                relY = 1 - ((relY - this.mar) / this.graphHeight);
                this.mouseY = bounds.yMin + relY * (bounds.yMax - bounds.yMin);

                if (this.inPtMove) {
                    this.curveSampleX = null;

                    // Update moved point
                    const moveX = this.mouseX - this.movingDeltaX;
                    const moveY = this.mouseY - this.movingDeltaY;
                    const pt = this.movingPt;
                    const idx = pt.idx;
                    if (pt.ctrlIdx !== undefined) {
                        const keyX = 'x'+(pt.ctrlIdx+1);
                        const keyY = 'y'+(pt.ctrlIdx+1);
                        this.movingCmd[idx][keyX] = moveX;
                        this.$set(this.movingCmd[idx], keyY, roundUtil(moveY, false));
                    } else {
                        if (!this.movingFixUtil) this.movingCmd[idx].y = roundUtil(moveY);
                        this.$set(this.movingCmd[idx], 'x', moveX);
                    }

                } else {
                    this.curveSampleX = Math.max(bounds.xMin, Math.min(bounds.xMax, this.mouseX));
                }
            },
            _onMouseOut() {
                this.curveSampleX = null;
                this.mouseX = null;
                this.mouseY = null;
            },
            _onMouseDown() {
                if (!this.editable || !this.showControlPoints || !this.hasClosest) return;

                // Start moving the closest point to our cursor
                this.curveSampleX = null;
                this.movingCmd = cloneDeep(this.cmd);
                this.inPtMove = true;
                this.movingPt = this.closestPoint;
                this.movingFixUtil = this.movingPt.idx === 0 || this.movingPt.idx === (this.cmd.length-1);

                this.movingDeltaX = this.mouseX-this.movingPt.x;
                this.movingDeltaY = this.mouseY-this.movingPt.y;
            },
            _onKeyPress(e, doAction) {
                if (e.key === 'Escape' && this.inPtMove) {
                    if (doAction) this._stopPtMove();
                    e.preventDefault();
                }
            },
            _onMouseUp() {
                if (this.inPtMove) {
                    this._input(this.movingCmd);
                }
                this._stopPtMove();
            },
            _stopPtMove() {
                this.inPtMove = false;
                this.movingPt = null;
                this.movingCmd = null;
            },
        },
        created() {
            this.$nextTick(() => {
                this._newValue(this.value);
            });
        },
        mounted() {
            this._onResize();
            dispatcher.onKeyPress(this._onKeyPress);
        },
    });
</script>

<style scoped>
    svg#curve {
        font-family: sans-serif;
        font-size: 14pt;
        font-weight: lighter;
        dominant-baseline: middle;
        text-anchor: middle;
        user-select: none;
    }

    svg#curve.pointer {
        cursor: grab;
    }
    svg#curve.pointer.dragging {
        cursor: grabbing;
    }
    svg#curve line {
        stroke: black;
        stroke-width: 1px;
        stroke-linecap: square;
    }
    svg#curve line.guide {
        stroke: green;
        stroke-width: 1px;
        stroke-linecap: square;
        stroke-opacity: 50%;
    }
</style>