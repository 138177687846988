import * as m from '@/model/model';
import {getNextId, editProject} from "@/store";
import slugify from 'slugify';

import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import fromPairs from 'lodash/fromPairs';
import {calcBounds, calcUtility, calcValue} from "@/model/utility";

export function addAttribute(name: string): m.Attribute {
    const attr: m.Attribute = {
        id: getNextId(),
        name,
        ref: getSlug(name),
        weight: 1,
    };

    editProject((project) => {
        project.attributes.push(attr);
    });
    return attr;
}
export function editAttribute(id: m.idType, edit: (attr: m.Attribute) => void, callback?: (attr: m.Attribute) => void,
                              influencesResults: boolean = false) {
    editProject((project) => {
        for (const attr of project.attributes) {
            if (attr.id == id) edit(attr);
        }
    }, undefined, true, true, (project) => {
        if (!callback) return;
        for (const attr of project.attributes) {
            if (attr.id == id) callback(attr);
        }
    }, influencesResults);
}
export function deleteAttribute(id: m.idType) {
    editProject((project) => {
        project.attributes = filter(project.attributes, (attr) => attr.id !== id);
    }, undefined, true, true, undefined, true);
}

type AttrWithWeight<T> = T & { weight: number };
export function hasWeight<T extends (m.Attribute|m.DesignPointAttribute)>(attr: T): attr is AttrWithWeight<T> {
    return attr.weight !== undefined && attr.weight !== null;
}
export function getDesignPointAttrWeight(dsAttr: m.DesignPointAttribute, attr: m.Attribute|null): number|null {
    if (hasWeight(dsAttr)) return dsAttr.weight;
    if (attr === null) return null;
    if (hasWeight(attr)) return attr.weight;
    return null;
}

export function getSlug(name: string): string {
    return slugify(name.replace(/_/g, ' '), {lower: true, remove: /[^a-zA-Z0-9_ ]/g});
}

export function getName(name: string, existing: string[]): string {
    if (!includes(existing, name)) return name;

    let i = 2;
    const base = name;
    while (includes(existing, name)) {
        name = base+' '+i.toString();
        i++;
    }
    return name;
}

export function editDesignStudy(id: m.idType, edit: (ds: m.DesignStudy, project: m.Project) => void,
                                influencesResults: boolean = false, influencesAll: boolean = false) {
    const influences = (influencesResults) ? ((influencesAll) ? true: [id]): false;
    editProject((project) => {
        for (const ds of project.designStudies) {
            if (ds.id == id) edit(ds, project);
        }
    }, undefined, true, true, undefined, influences);
}
export function editDesignStudyAttribute(dsId: m.idType, idx: number,
                                         edit: (attr: m.DesignPointAttribute, ds: m.DesignStudy) => void) {
    editDesignStudy(dsId, (ds) => {
        const attr = ds.attributes[idx];
        if (attr) edit(attr, ds);
    }, true);
}
export function deleteDesignStudy(id: m.idType, callback?: (project: m.Project) => void) {
    editProject((project) => {
        project.designStudies = filter(project.designStudies, (ds) => ds.id !== id);
    }, undefined, true, true, callback, [id]);
}

export function applyDesignStudyAttributeProjectWide(dsId: m.idType, idx: number) {
    editDesignStudy(dsId, (ds, project) => {
        const dsAttr = ds.attributes[idx];
        if (!dsAttr || !dsAttr.attributeId) return;

        for (const attr of project.attributes) {
            if (attr.id === dsAttr.attributeId) {

                if (dsAttr.weight !== null && dsAttr.weight !== undefined) {
                    attr.weight = dsAttr.weight;
                    dsAttr.weight = null;
                }
                if (dsAttr.utilityFunctionPath !== null && dsAttr.utilityFunctionPath !== undefined) {
                    attr.utilityFunctionPath = dsAttr.utilityFunctionPath;
                    attr.bounds = dsAttr.bounds;
                    attr.boundsZeroUtility = dsAttr.boundsZeroUtility;

                    dsAttr.utilityFunctionPath = null;
                    dsAttr.bounds = null;
                    dsAttr.boundsZeroUtility = null;
                }

                break;
            }
        }

    }, true, true);
}

/** Should implement same logic as model/utility.py::UtilityCalc.update_design_study! */
export function updateDesignStudy(attributes: m.Attribute[], ds: m.DesignStudy, designPoints?: m.DesignPoint[]) {
    // Update design points
    const values = [];
    for (const dp of (designPoints || ds.designPoints)) {
        fillDesignPoint(dp, ds.attributes, attributes);
        values.push(dp.value as number);
    }

    // Update best value
    if (values.length === 0) {
        ds.bestValue = null;
        ds.bestValueIdx = null;
    } else {
        let bestValue = values[0];
        let bestValueIdx = 0;
        for (let i = 1; i < values.length; i++) {
            if (values[i] > bestValue) {
                bestValue = values[i];
                bestValueIdx = i;
            }
        }
        ds.bestValue = bestValue;
        ds.bestValueIdx = bestValueIdx;
    }
}

/** Should implement same logic as model/utility.py::UtilityCalc._fill_design_point! */
function fillDesignPoint(dp: m.DesignPoint, dpAttributes: m.DesignPointAttribute[], attributes: m.Attribute[]) {
    if (attributes.length != dpAttributes.length) throw new Error('Malformed attributes list!');
    const attrMap: {[key: number]: m.Attribute} = fromPairs(map(attributes, (attr) => [attr.id, attr]));

    function processDesignPointAttribute(idx: number, dpAttr: m.DesignPointAttribute): boolean {
        // Get corresponding attribute
        if (!dpAttr.attributeId) return false;
        if (!(dpAttr.attributeId in attrMap)) return false;
        const attr = attrMap[dpAttr.attributeId];

        // Get utility curve definition and associated bounds
        let utilityCurve, bounds, boundsU0;
        if (!!dpAttr.utilityFunctionPath) {
            ({utilityFunctionPath: utilityCurve, bounds, boundsZeroUtility: boundsU0} = dpAttr);
        } else {
            ({utilityFunctionPath: utilityCurve, bounds, boundsZeroUtility: boundsU0} = attr);
        }
        if (!utilityCurve) return false;

        if (!bounds || !boundsU0) {
            [bounds, boundsU0] = calcBounds(utilityCurve);
        }

        // Determine attribute weight
        const weight = (dpAttr.weight !== undefined && dpAttr.weight !== null) ? dpAttr.weight: attr.weight;
        if (weight === undefined || weight === null) return false;

        // Get float content
        const attrContent = dp.attributes[idx];
        let content = attrContent.rawContent;
        if (typeof content === 'string') {
            if (!attr.contentMap || !(content in attr.contentMap)) throw new Error(`Content not found in content map: ${content}`);
            content = attr.contentMap[content];
        }

        // Calculate utility and store results
        attrContent.weight = weight;
        attrContent.content = content;
        attrContent.utility = calcUtility(utilityCurve, content, bounds, boundsU0);
        return true;
    }

    // Calculate utilities for design point attributes
    const utilities: number[] = [];
    const weights: number[] = [];
    for (let i = 0; i < dpAttributes.length; i++) {
        const isAssigned = processDesignPointAttribute(i, dpAttributes[i]);

        const attrContent = dp.attributes[i];
        if (isAssigned) {
            if (!isNaN(attrContent.utility as number)) {
        utilities.push(attrContent.utility as number);
        weights.push(attrContent.weight as number);
            }
        } else {
            attrContent.weight = null
            attrContent.content = null
            attrContent.utility = null
        }
    }

    // Calculate design point value
    dp.value = (utilities.length > 0) ? calcValue(utilities, weights): 0;
}
