import b64ToBlob from 'b64-to-blob';
import {saveAs} from 'file-saver';
import fileDialog from "file-dialog";
import {FileContent, base64Encode} from "./api";

export function saveFileContentCallback(fileContent: FileContent) {
    saveEncodedFile(fileContent.filename, fileContent.data, fileContent.mimeType);
}

export function saveEncodedFile(name: string, data: string, mimeType: string): void {
    const blob = b64ToBlob(data, mimeType);
    saveBlob(name, blob);
}

export function saveFile(name: string, data: string, mimeType: string): void {
    const byteArray = (new TextEncoder()).encode(data);
    const blob = new Blob([byteArray], { type: mimeType });
    saveBlob(name, blob);
}

export function saveBlob(name: string, blob: Blob): void {
    saveAs(blob, name);
}

export function saveDataUrl(name: string, url: string) {
    saveAs(url, name);
}

export function openFile(callback: (data: string, fileName: string) => void): void {
    fileDialog().then((files: FileList) => {
        base64Encode(files[0], (data) => {
            callback(data, files[0].name);
        });
    });
}
