import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import App from './app.vue';
import {store} from './store';

// Import styles and define theme
import './style.css';

Vue.config.productionTip = false;

Vue.use(Vuetify);
const vuetify = new Vuetify({
    icons: {
        iconfont: 'mdiSvg', // https://vuetifyjs.com/en/features/icon-fonts/#material-design-icons-js-svg
    },
});

// Import and instantiate the API and Dispatcher
import Dispatcher from "./dispatcher";
export const dispatcher = new Dispatcher();

import Api from "./api";
// @ts-ignore
export const api = new Api(appSettings);

export const valLight = '#A5D6A7';
export const valDark = '#4CAF50';
export const bgOverlay = 'grey lighten-4';

// @ts-ignore
import VueHeadful from 'vue-headful';
Vue.component('vue-headful', VueHeadful);

// @ts-ignore
store.commit.commitSettings(appSettings);

new Vue({
    vuetify,
    render: h => h(App)
}).$mount('#app');
