<template>
    <v-card max-width="500">
        <v-card-title>{{ attrName }}</v-card-title>
        <v-card-text>

            <v-text-field
                v-model="editAttrWeight"
                :disabled="!editable"
                class="mt-0 pt-0"
                :label="label"
                :rules="(isDsAttr) ? [rules.float]: [rules.required, rules.float]"
                :placeholder="placeholder"
                @blur="_updateAttrWeight"
                @keyup.enter.stop="_updateAttrWeight"
                :clearable="editable && isDsAttr"
                ref="wt"
            ></v-text-field>

            <h3 class="text-center mb-3 mt-0">Relative weight: {{ fmtRelWeight }}%</h3>

            <v-divider />

            <utility-curve
                v-if="editUtilityFunctionPath"
                class="util-curve-area"
                :class="{pointer: curveClickable}"
                v-model="editUtilityFunctionPath"
                :bounds="bounds"
                :editable="curveEditable"
                :show-control-points="curveEditable"
                :show-reset-control-points="curveEditable && hasOwnUtilityFunction"
                :height="200"
                @click.native="_curveClick"
                @reset="_resetUtilityFunction"
            />

        </v-card-text>
      <v-card-actions >
            <v-spacer />
            <v-btn text color="primary" @click="_updateUtilityFunction">Apply</v-btn>
        </v-card-actions>
        <v-card-actions v-if="showApplyProject && overridesProps">
            <v-spacer />
            <v-btn text @click="_resetAttr">Reset</v-btn>
            <v-btn text color="primary" @click="_applyClick">Apply Project-wide</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import Vue from "vue";
    import {fmt} from "./utility-curve";
    import {hasWeight} from "@/model/ops";
    import {calcBounds} from "@/model/utility";
    import {defaultPath, renderCommands} from "@/comp/utility-curve";

    import merge from 'lodash/merge';

    import utilityCurve from './utility-curve.vue';

    export default Vue.extend({
        name: "attr-card",
        components: {
            utilityCurve,
        },
        props: {
            attr: {type: Object, required: true},
            originalAttr: {type: Object, default: null},
            totalWeight: {required: true},
            editable: {required: true},
            curveEditable: {default: false},
            curveClickable: {default: false},
            showApplyProject: {default: false},
        },
        data: () => ({
            editAttrWeight: 0,
            editUtilityFunctionPath: null,

            rules: {
                required: (value) => !!value || parseFloat(value) === 0 || 'Required',
                float: (value) => !value || !isNaN(parseFloat(value)) || 'Must be a numeric value',
            },
        }),
        watch: {
            attr: {
                handler() {
                    this._attrUpdated();
                },
                deep: true,
            },
        },
        computed: {
            isDsAttr() {
                return this.originalAttr !== null;
            },
            attrName() {
                if (this.isDsAttr) return `${this.originalAttr.name} (${this.attr.name})`;
                return this.attr.name;
            },
            attrWeight() {
                return this.attr.weight;
            },
            hasAttrWeight() {
                return hasWeight(this.attr);
            },
            weight() {
                if (this.hasAttrWeight) return this.attrWeight;
                if (!this.isDsAttr) return 0;
                return this.originalAttr.weight;
            },
            relWeight() {
                return this.weight/this.totalWeight;
            },
            fmtRelWeight() {
                return fmt(this.relWeight*100, 1);
            },
            hasOwnUtilityFunction() {
                return this.attr.utilityFunctionPath !== null && this.attr.utilityFunctionPath !== undefined;
            },
            utilityFunctionPath() {
                if (this.hasOwnUtilityFunction) return this.attr.utilityFunctionPath;
                if (!this.isDsAttr) return renderCommands(defaultPath);
                return this.originalAttr.utilityFunctionPath;
            },
            bounds() {
                if (this.hasOwnUtilityFunction) return this.attr.bounds;
                if (!this.isDsAttr) return calcBounds(renderCommands(defaultPath));
                return this.originalAttr.bounds;
            },
            placeholder() {
                if (!this.isDsAttr) return null;
                return this.originalAttr.weight.toString();
            },
            label() {
                if (!this.isDsAttr) return 'Weight';
                return `Weight: ${this.placeholder}`;
            },
            overridesProps() {
                return this.isDsAttr && (this.hasAttrWeight || this.hasOwnUtilityFunction);
            },
        },
        methods: {
            _attrUpdated() {
                this.editAttrWeight = this.attr.weight || 0;
                this.editUtilityFunctionPath = this.utilityFunctionPath;
            },
            _updateAttrWeight() {
                const fieldRef = this.$refs.wt;
                if (fieldRef && fieldRef.hasError) return;

                this._emitUpdate({
                    weight: (this.editAttrWeight) ? (parseFloat(this.editAttrWeight) || 0): null,
                });
            },
            _updateUtilityFunction() {
                const utilityFunctionPath = this.editUtilityFunctionPath;
                const [bounds, boundsU0] = calcBounds(utilityFunctionPath);
                this._emitUpdate({
                    utilityFunctionPath,
                    bounds,
                    boundsZeroUtility: boundsU0,
                });
            },
            _resetUtilityFunction() {
                this._emitUpdate({
                    utilityFunctionPath: null,
                    bounds: null,
                    boundsZeroUtility: null,
                });
            },
            _resetAttr() {
                this._emitUpdate({
                    weight: null,
                    utilityFunctionPath: null,
                    bounds: null,
                    boundsZeroUtility: null,
                });
            },
            _emitUpdate(props) {
                const attr = merge({}, this.attr, props);
                this.$emit('input', attr);
            },
            _curveClick() {
                if (this.curveClickable) this.$emit('click');
            },
            _applyClick() {
                if (this.showApplyProject) this.$emit('apply');
            },
        },
        mounted() {
            this._attrUpdated();
        },
    });
</script>

<style scoped>
    .util-curve-area.pointer {
        cursor: pointer;
    }
</style>