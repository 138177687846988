<template>
    <div class="fill-height">
        <div class="fill-height d-flex flex-column">
            <!-- Design studies toolbar -->
            <v-toolbar
                height="40"
                width="100%"
                color="blue-grey lighten-5"
                class="content-toolbar flex-grow-0"
            >
                <!-- Center page buttons -->
                <div class="toolbar-center">
                    <div>
                        <v-btn depressed small
                               :color="(page === null) ? pageSelColor: pageColor"
                               @click="page = null"
                        >Overview</v-btn>

                        <v-btn depressed small
                               class="ml-4"
                               :color="(page === 'table') ? pageSelColor: pageColor"
                               @click="page = 'table'"
                        >Table</v-btn>

                        <v-btn depressed small
                               class="ml-4"
                               :color="(page === 'plot') ? pageSelColor: pageColor"
                               @click="page = 'plot'"
                        >Plot</v-btn>
                    </div>
                </div>
            </v-toolbar>

            <div class="flex-grow-1" style="position: relative">
                <!-- No design studies yet: display import button -->
                <div v-if="designStudies.length === 0" class="content-pane-wrapper align-center justify-center fill-height d-flex">
                    <div class="flex flex-grow-0 text-center" v-if="canImport">
                        <v-btn v-if="canImport" color="primary" @click="_import"
                        ><v-icon left>{{ mdiPlus }}</v-icon> Import Design Study</v-btn>
                    </div>
                </div>

                <!-- Main content -->
                <div v-else class="content-pane-wrapper d-flex align-stretch">

                    <!-- Left pane -->
                    <div class="col-3 pa-0 scroll">
                        <v-container fluid>
                            <!-- Add buttons -->
                            <div class="d-flex justify-center mb-3" v-if="canImport">
                                <div>
                                    <v-btn
                                        v-if="canImport"
                                        color="primary"
                                        @click="_import"
                                    ><v-icon left>{{ mdiPlus }}</v-icon>Load</v-btn>
                                </div>
                            </div>

                            <v-treeview
                                :active.sync="selected"
                                activatable hoverable open-all
                                :items="items"
                                v-model="multiSelected"
                                :selectable="showMultiSelect"
                                class="mx-n3 pointer"
                                ref="tree"
                            >
                                <template v-slot:prepend="{ item }">
                                    <v-icon>{{ mdiChartScatterPlot }}</v-icon>
                                </template>
                                <template v-slot:append="{ item }">
                                    <v-icon v-if="!item.allLinked" color="warning" title="Not all attributes linked"
                                    >{{ mdiLinkOff }}</v-icon>

                                    <v-btn @click.stop="_exportStudy(item.id)" icon
                                           title="Export Design Study (CSV)"><v-icon>{{ mdiDownload }}</v-icon></v-btn>
                                    <v-btn @click.stop="_copyStudy(item.id)" icon v-if="canAddRemove"
                                           title="Copy Design Study"><v-icon>{{ mdiContentCopy }}</v-icon></v-btn>
                                    <v-btn @click.stop="_deleteStudy(item.id)" icon v-if="canAddRemove"
                                           title="Delete Design Study"><v-icon>{{ mdiTrashCan }}</v-icon></v-btn>
                                </template>
                            </v-treeview>
                        </v-container>
                    </div>

                    <v-divider vertical style="z-index: 10;" />

                    <!-- Main pane -->
                    <div class="col-6 pa-0 scroll">
                        <!-- Make sure to update `showingResults`! -->
                        <settings v-if="hasSelection && (page === null)" :design-study="designStudy" />
                        <ds-table v-if="hasSelection && (page === 'table')" :design-study="designStudy"
                                  :loading="loadingResults" />
                        <plotting v-if="hasMultiSelection && (page === 'plot')" :design-study-ids="multiSelected"
                                  :loading="loadingResults" />
                    </div>

                    <!-- Right pane -->
                    <v-divider vertical style="z-index: 10;" v-if="hasSelection" />
                    <div class="col-3 pa-0 scroll" v-if="hasSelection">
                        <h2 style="text-align: center">Attributes of: {{ designStudy.name }}</h2>

                        <ds-attrs
                            :design-study="designStudy"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- Design study import dialog -->
        <v-dialog
            v-model="importDialog"
            max-width="800"
            @click:outside="importDialog = false"
        >
            <v-card :loading="importPending || importSetsLoading">
                <v-card-title class="headline text-center">Import Design Study</v-card-title>

                <!-- Import from a list of available design studies -->
                <v-card-text v-if="importBackendSets">
                    <v-radio-group v-if="importSets.length > 0" v-model="importSetIdx">
                        <v-radio v-for="(title, idx) in importSets" :key="idx" :value="idx">
                            <template v-slot:label>
                                <div>{{ title }}</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <div v-else class="text-center grey--text">Nothing to import...</div>
                </v-card-text>

                <!-- Import from file -->
                <v-card-text v-else>
                    <v-radio-group v-model="importKey">
                        <v-radio v-for="(value, key) in importKeys" :key="key" :value="key">
                            <template v-slot:label>
                                <div>
                                    <span style="font-weight: bold">{{ value[0] }}: </span>
                                    <span style="font-size: small">{{ value[1] }}</span>
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="importDialog = false">Close</v-btn>

                    <v-btn v-if="importBackendSets" text color="primary" @click="_importSet"
                           :disabled="importSetIdx === null || importSets.length === 0">Import</v-btn>
                    <v-btn v-else text color="primary" @click="_importSelectFile"
                           :disabled="!importKey">Select File</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Delete design study dialog -->
        <v-dialog v-model="deleteDesignStudyDialog" max-width="350">
            <v-card>
                <v-card-title class="headline">Delete design study?</v-card-title>
                <v-card-text>{{ deleteName }}</v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="deleteDesignStudyDialog = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="_doDeleteStudy">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from "vue";
    import {loadDesignPointsIfNeeded, store, updatedProject} from "@/store";
    import {api, dispatcher} from "@/main";
    import {openFile, saveFileContentCallback} from "@/files";
    import {deleteDesignStudy} from "@/model/ops";
    import {allLinked} from "./ds";

    import {mdiPlus, mdiTrashCan, mdiChartScatterPlot, mdiLinkOff, mdiContentCopy, mdiDownload} from '@mdi/js';

    import map from "lodash/map";
    import find from "lodash/find";
    import uniq from "lodash/uniq";
    import concat from "lodash/concat";
    import includes from "lodash/includes";

    import settings from './settings.vue';
    import dsTable from './ds-table.vue';
    import plotting from './plotting.vue';
    import dsAttrs from './ds-attrs.vue';

    export default Vue.extend({
        name: "design-studies",
        components: {
            settings,
            dsTable,
            plotting,
            dsAttrs,
        },
        data: () => ({
            mdiPlus, mdiTrashCan, mdiChartScatterPlot, mdiLinkOff, mdiContentCopy, mdiDownload,

            selected: [],
            multiSelected: [],
            loadingResults: false,

            page: null,
            pageColor: 'blue-grey lighten-5',
            pageSelColor: 'blue-grey lighten-3',

            deleteDesignStudyDialog: false,
            deleteId: null,

            importDialog: false,
            importKeys: {
                csv: ['CSV', 'imports a CSV file.'],
                excel: ['Excel', 'imports the first sheet from an Excel file (*.xlsx).'],
                adore: ['ADORE', 'imports the architectures of an ADORE project (*.adore).'],
                cpacs: ['CPACS', 'imports a CPACS file with VALORISE toolspecific data.'],
                cpacs_zip: ['CPACS (zip)', 'imports a *.zip file containing CPACS files representing a design study.'],
            },
            importKey: 'csv',
            importPending: false,
            importSets: [],
            importSetIdx: null,
            importSetsLoading: false,

            rules: {
                required: (value) => !!value || 'Required',
            },
        }),
        watch: {
            showingResults() {
                this._ensureResults();
            },
            selectedId(selectedId) {
                this._ensureResults();

                if (this.showMultiSelect && this.multiSelected.length === 0) {
                    this.multiSelected = [selectedId];
                }
            },
            multiSelected() {
                this._ensureResults();
            },
            showMultiSelect(showMultiSelect) {
                if (showMultiSelect && this.hasSelection && this.multiSelected.length === 0) {
                    this.multiSelected = [this.selectedId];
                }
            },
        },
        computed: {
            project: () => store.state.localProject,
            editable: () => store.state.editable,
            canAddRemove() {
                return this.editable && store.state.settings.edit_ds;
            },
            canEditProps() {
                return this.canAddRemove;
            },
            canImport() {
                return this.editable && store.state.settings.import_ds;
            },
            importBackendSets() {
                return store.state.settings.db_provides_ds;
            },
            canEditRef() {
                return this.editable && store.state.settings.edit_refs;
            },
            attrs() {
                return (this.project) ? this.project.attributes: [];
            },
            designStudies() {
                return (this.project) ? this.project.designStudies: [];
            },
            items() {
                return map(this.designStudies, (ds) => {
                    return {
                        id: ds.id,
                        name: ds.name,
                        allLinked: allLinked(ds),
                    };
                });
            },
            selectedId() {
                return (this.selected.length > 0) ? this.selected[0]: null;
            },
            designStudy() {
                if (this.selectedId === null) return {};
                const selectedId = this.selectedId;
                return find(this.designStudies, (ds) => ds.id === selectedId) || {};
            },
            hasSelection() {
                return this.selectedId !== null;
            },
            designStudyId() {
                return (this.hasSelection) ? this.designStudy.id: null;
            },
            showingResults() {
                return includes(['table', 'plot'], this.page);
            },
            showMultiSelect() {
                return this.page === 'plot';
            },
            hasMultiSelection() {
                return this.multiSelected.length > 0;
            },
            deleteName() {
                if (!this.deleteId) return '';
                const ds = find(this.designStudies, (ds) => ds.id === this.deleteId);
                return (ds) ? ds.name: '';
            },
        },
        methods: {
            _unselect() {
                this.selected = [];
            },
            _import() {
                if (!this.canImport) return;
                this.importPending = false;
                this.importDialog = true;

                if (this.importBackendSets) {
                    this.importSets = [];
                    this.importSetIdx = null;
                    this.importSetsLoading = true;
                    api.getDesignStudySets((sets) => {
                        this.importSetIdx = 0;
                        this.importSets = sets;
                        this.importSetsLoading = false;
                    });
                }
            },
            _importSet() {
                if (!this.canImport || !this.importBackendSets) return;

                this.importPending = true;
                api.loadDesignStudy(this.importSetIdx, this._importCallback, this._importErrorCallback);
            },
            _importSelectFile() {
                if (!this.canImport) return;
                if (!this.importKey) return;
                openFile((data, fileName) => {
                    this.importPending = true;

                    let name = fileName.split('.');
                    if (name.length > 1) name.splice(name.length-1, 1);
                    name = name.join('.');

                    api.importDesignStudy(this.importKey, data, name, this._importCallback, this._importErrorCallback);
                });
            },
            _importCallback(project, response) {
                updatedProject(project, response);
                this.importPending = false;
                this.importDialog = false;
                this.selected = [project.designStudies[project.designStudies.length-1].id];
            },
            _importErrorCallback() {
                this.importPending = false;
            },
            _exportStudy(dsId) {
                api.exportDesignStudy('csv', dsId, saveFileContentCallback);
            },
            _copyStudy(dsId) {
                if (!this.canImport || !this.canAddRemove) return;
                api.copyDesignStudy(dsId, updatedProject);
            },
            _deleteStudy(dsId) {
                if (!this.canImport || !this.canAddRemove) return;
                this.deleteId = dsId;
                this.deleteDesignStudyDialog = true;
            },
            _doDeleteStudy() {
                this.deleteDesignStudyDialog = false;
                deleteDesignStudy(this.deleteId, () => {
                    this.selected = [];
                    this.multiSelected = [];
                });
                this.deleteId = null;
            },

            _onResultsInvalidated(dsId) {
                if (this.hasSelection && this.designStudy.id === dsId) this._ensureResults();
            },
            _ensureResults() {
                if (!this.showingResults) return;

                let shownDesignStudyIds = [];
                if (this.hasSelection) shownDesignStudyIds.push(this.designStudy.id);
                shownDesignStudyIds = uniq(concat(shownDesignStudyIds, this.multiSelected));
                if (shownDesignStudyIds.length === 0) return;

                const timeoutId = setTimeout(() => { this.loadingResults = true; }, 50);

                Promise.all(map(shownDesignStudyIds, (dsId) => new Promise((resolve => {
                    loadDesignPointsIfNeeded(dsId, resolve);
                })))).then(() => {
                    this.loadingResults = false;
                    clearTimeout(timeoutId);
                });
            },
        },
        created() {
            dispatcher.onFileOps(this._unselect);
        },
        mounted() {
            dispatcher.onResultsStateInvalidated(this._onResultsInvalidated);
            this._ensureResults();
        },
    });
</script>

<style scoped>
    .v-toolbar.content-toolbar {
        z-index: 40;
    }
</style>