<template>
    <div class="fill-height">
        <div class="fill-height d-flex align-stretch">
            <!-- Left pane -->
            <div class="col-3 pa-0 scroll">
                <v-container fluid>
                    <!-- Add buttons -->
                    <div class="d-flex justify-center mb-3" v-if="editable">
                        <div>
                            <v-btn
                                v-if="canAddRemove"
                                :color="(canImport) ? null: 'primary'"
                                @click="_addAttr"
                            ><v-icon left>{{ mdiPlus }}</v-icon>Attribute</v-btn>

                            <v-btn
                                v-if="canImport"
                                color="primary"
                                :class="{'ml-2': canAddRemove}"
                                @click="_import"
                            ><v-icon left>{{ mdiUpload }}</v-icon>Import</v-btn>
                        </div>
                    </div>

                    <v-treeview
                        :active.sync="selected"
                        activatable hoverable open-all
                        :items="items"
                        class="mx-n3 pointer"
                        ref="tree"
                    >
                        <template v-slot:prepend="{ item }">
                            <v-icon>{{ mdiChartBellCurveCumulative }}</v-icon>
                        </template>
                        <template v-slot:label="{ item }">
                            {{ item.name }}
                            <span v-if="item.unit" class="grey--text ml-2">{{ item.unit }}</span>
                        </template>
                        <template v-slot:append="{ item }">
                            <span class="grey--text">Wt: {{ item.weight }} ({{ item.relWeight }}%)</span>

                            <v-btn @click.stop="_deleteAttr(item.id)" icon v-if="canAddRemove"
                            ><v-icon>{{ mdiTrashCan }}</v-icon></v-btn>
                        </template>
                    </v-treeview>
                </v-container>
            </div>

            <v-divider vertical v-if="hasAttrs" />

            <!-- Overview screen -->
            <div class="col-9 pa-0 scroll" v-if="hasAttrs && !hasSelection">
                <v-container>
                    <h1 class="mb-2">Attributes Overview</h1>

                    <v-row>
                        <v-col v-for="(attr, idx) in sortedAttrs" :key="idx" cols="12" md="6" lg="4">
                            <attr-card
                                :editable="canEditProps"
                                :total-weight="totalWeight"
                                :attr="attr"
                                :curve-clickable="true"
                                @input="_updateAttrWeight(idx, $event)"
                                @click="selected = [attr.id]"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </div>

            <!-- Edit screen -->
            <div class="col-3 pa-0 scroll" v-if="hasSelection">
                <v-container fluid>
                    <h1>Editing Attribute
                        <v-btn color="error" small @click="selected = []"
                               style="position: relative; top: -2px;">Close</v-btn>
                    </h1>

                    <v-form v-model="formValid" ref="form" @submit.prevent="">
                        <!-- Name field -->
                        <v-text-field
                            v-model="editedItem.name"
                            :disabled="!canEditProps"
                            label="Name"
                            @blur="_updateItem()"
                            @keyup.enter.stop="_updateItem()"
                            :rules="[rules.required]"
                        ></v-text-field>

                        <!-- Ref field -->
                        <v-text-field
                            v-model="editedItem.ref"
                            :disabled="!canEditRef"
                            label="External reference"
                            hide-details="auto"
                            @blur="_updateItem()"
                            @keyup.enter.prevent="_updateItem()"
                        ></v-text-field>

                        <!-- Unit field -->
                        <v-text-field
                            v-model="editedItem.unit"
                            :disabled="!canEditProps"
                            label="Units"
                            @blur="_updateItem()"
                            @keyup.enter.stop="_updateItem()"
                        ></v-text-field>

                        <!-- Weight selector -->
                        <v-text-field
                            v-model="editedItem.weight"
                            :disabled="!canEditProps"
                            class="mt-0 pt-0"
                            label="Weight"
                            :rules="[rules.required, rules.float]"
                            @blur="_updateItem()"
                            @keyup.enter.stop="_updateItem()"
                        ></v-text-field>

                    </v-form>
                </v-container>
            </div>

            <v-divider vertical v-if="hasSelection" />

            <!-- Edit utility curve screen -->
            <div class="col pa-0 scroll" v-if="hasSelection">
                <v-container fluid>
                    <h1>Utility Curve</h1>
                    <utility-curve
                        v-model="editedItem.utilityFunctionPath"
                        :bounds="editedItem.bounds"
                        :editable="canEditProps"
                        @input="_updateUtilityFunction"
                    />
                </v-container>
            </div>
        </div>

        <!-- Attributes import dialog -->
        <v-dialog
            v-model="importDialog"
            max-width="800"
            @click:outside="importDialog = false"
        >
            <v-card :loading="importPending || importSetsLoading">
                <v-card-title class="headline text-center">Import Attributes</v-card-title>

                <!-- Import from a list of available attributes -->
                <v-card-text v-if="importBackendSets">
                    <v-radio-group v-if="importSets.length > 0" v-model="importSetIdx">
                        <v-radio v-for="(title, idx) in importSets" :key="idx" :value="idx">
                            <template v-slot:label>
                                <div>{{ title }}</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <div v-else class="text-center grey--text">Nothing to import...</div>
                </v-card-text>

                <!-- Import from file -->
                <v-card-text v-else>
                    <v-radio-group v-model="importKey">
                        <v-radio v-for="(value, key) in importKeys" :key="key" :value="key">
                            <template v-slot:label>
                                <div>
                                    <span style="font-weight: bold">{{ value[0] }}: </span>
                                    <span style="font-size: small">{{ value[1] }}</span>
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="importDialog = false">Close</v-btn>

                    <v-btn v-if="importBackendSets" text color="primary" @click="_importSet"
                           :disabled="importSetIdx === null || importSets.length === 0">Import</v-btn>
                    <v-btn v-else text color="primary" @click="_importSelectFile"
                           :disabled="!importKey">Select File</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from "vue";
    import {store, updatedProject} from "@/store";
    import {api, dispatcher} from "@/main";
    import {openFile} from "@/files";
    import {getName, addAttribute, editAttribute, deleteAttribute, hasWeight, getSlug} from "@/model/ops";
    import {calcBounds} from "@/model/utility";
    import {fmt} from "@/comp/utility-curve";

    import {mdiPlus, mdiTrashCan, mdiUpload, mdiChartBellCurveCumulative} from '@mdi/js';

    import map from 'lodash/map';
    import sum from 'lodash/sum';
    import find from 'lodash/find';
    import sortBy from 'lodash/sortBy';
    import cloneDeep from 'lodash/cloneDeep';

    import utilityCurve from './utility-curve.vue';
    import attrCard from './attr-card.vue';

    export default Vue.extend({
        name: "attributes",
        components: {
            utilityCurve,
            attrCard,
        },
        data: () => ({
            mdiPlus, mdiTrashCan, mdiUpload, mdiChartBellCurveCumulative,

            formValid: false,
            editedItem: {},
            refValue: null,

            selected: [],

            importDialog: false,
            importKeys: {
                csv: ['CSV', 'imports the headers from a CSV file.'],
                excel: ['Excel', 'imports the first row of the first sheet from an Excel file (*.xlsx).'],
                adore: ['ADORE', 'imports the QOIs of an ADORE design space (*.adore).'],
                cpacs: ['CPACS', 'imports attributes defined in the VALORISE toolspecific section.'],
            },
            importKey: 'csv',
            importPending: false,
            importSets: [],
            importSetIdx: null,
            importSetsLoading: false,

            rules: {
                required: (value) => !!value || parseFloat(value) === 0 || 'Required',
                float: (value) => !value || !isNaN(parseFloat(value)) || 'Must be a numeric value',
            },
        }),
        watch: {
            hasSelection() {
                this._changeSelection();
            },
            selectedId() {
                this._changeSelection();
            },
        },
        computed: {
            project: () => store.state.localProject,
            editable: () => store.state.editable,
            hasBackendUI: () => store.state.settings.has_backend_ui,
            canAddRemove() {
                return this.editable && store.state.settings.edit_attr;
            },
            canEditProps() {
                return this.canAddRemove;
            },
            canImport() {
                return this.editable && store.state.settings.import_attr;
            },
            importBackendSets() {
                return store.state.settings.db_provides_attr;
            },
            canEditRef() {
                return this.editable && store.state.settings.edit_refs;
            },
            attrs() {
                return (this.project) ? this.project.attributes: [];
            },
            hasAttrs() {
                return this.attrs.length > 0;
            },
            sortedAttrs() {
                return sortBy(this.attrs, 'name');
            },
            totalWeight() {
                return sum(map(this.attrs, (attr) => attr.weight));
            },
            relWeights() {
                const totalWeight = this.totalWeight;
                return map(this.sortedAttrs, (attr) => {
                    const weight = (hasWeight(attr)) ? attr.weight: 1;
                    return (totalWeight === 0) ? 0: weight/totalWeight;
                });
            },
            items() {
                const relWeights = this.relWeights;
                return map(this.sortedAttrs, (attr, idx) => {
                    const weight = (hasWeight(attr)) ? attr.weight: 1;
                    const relWeight = relWeights[idx];
                    return {
                        id: attr.id,
                        name: attr.name,
                        unit: attr.unit,
                        weight,
                        relWeight: fmt(relWeight*100, 1),
                    };
                });
            },

            selectedId() {
                return (this.selected.length > 0) ? this.selected[0]: null;
            },
            hasSelection() {
                return this.selectedId !== null;
            },
            selectedItem() {
                if (!this.hasSelection) return {};
                const selectedId = this.selectedId;
                return find(this.attrs, (attr) => attr.id === selectedId) || {};
            },
        },
        methods: {
            _addAttr() {
                const attrNames = map(this.attrs, (attr) => attr.name);
                const attr = addAttribute(getName('New attribute', attrNames));
                this._select(attr.id);
            },
            _deleteAttr(id) {
                deleteAttribute(id);
                if (this.selectedId === id) this._select(null);
            },
            _import() {
                if (!this.canImport) return;
                this.importPending = false;
                this.importDialog = true;

                if (this.importBackendSets) {
                    this.importSets = [];
                    this.importSetIdx = null;
                    this.importSetsLoading = true;
                    api.getAttrSets((sets) => {
                        this.importSetIdx = 0;
                        this.importSets = sets;
                        this.importSetsLoading = false;
                    });
                }
            },
            _importSet() {
                if (!this.canImport || !this.importBackendSets) return;

                this.importPending = true;
                api.loadAttrSet(this.importSetIdx, this._importCallback, this._importErrorCallback);
            },
            _importSelectFile() {
                if (!this.canImport) return;
                if (!this.importKey) return;
                openFile((data) => {
                    this.importPending = true;
                    api.importAttr(this.importKey, data, this._importCallback, this._importErrorCallback);
                });
            },
            _importCallback(project, response) {
                updatedProject(project, response);
                this.importPending = false;
                this.importDialog = false;
                this._changeSelection();
            },
            _importErrorCallback() {
                this.importPending = false;
            },
            _select(id) {
                this.selected = (id) ? [id]: [];
            },

            _onFileOps() {
                this._select(null);
            },
            _changeSelection() {
                if (!this.hasSelection) return;
                if (this.$refs.form) this.$refs.form.resetValidation();

                this.editedItem = cloneDeep(this.selectedItem);
                if (!hasWeight(this.editedItem)) this.editedItem.weight = 0;
                this.refValue = getSlug(this.editedItem.name);
            },
            _updateUtilityFunction() {
                const item = this.editedItem;

                const [bounds, boundsU0] = calcBounds(item.utilityFunctionPath);
                item.bounds = bounds;
                item.boundsZeroUtility = boundsU0;

                this._updateItem(true);
            },
            _updateAttrWeight(idx, inputData) {
                const attr = this.sortedAttrs[idx];
                editAttribute(attr.id, (attr) => {
                    attr.weight = parseFloat(inputData.weight) || 0;
                }, undefined, true);
            },
            _updateItem(updateUtilityFunc) {
                this.$refs.form.validate();
                this.$nextTick(() => {
                    if (!this.$refs.form.validate()) return;
                    if (!this.formValid) return;

                    const item = this.editedItem;
                    const influencesResults = updateUtilityFunc || (item.weight !== this.selectedItem.weight);
                    editAttribute(this.selectedId, (attr) => {
                        if (this.canEditRef) {
                            if (!item.ref || item.ref === this.refValue) {
                                item.ref = getSlug(item.name);
                                this.refValue = item.ref;
                            }
                            attr.ref = item.ref;
                        }

                        attr.name = item.name;
                        attr.unit = item.unit;
                        attr.weight = parseFloat(item.weight) || 0;

                        if (updateUtilityFunc) {
                            attr.utilityFunctionPath = item.utilityFunctionPath;
                            attr.bounds = item.bounds;
                            attr.boundsZeroUtility = item.boundsZeroUtility;
                        }

                    }, null, influencesResults);
                });
            },
        },
        created() {
            dispatcher.onFileOps(this._onFileOps);
            dispatcher.onUndoRedo(this._changeSelection);
        },
    });
</script>

<style scoped>

</style>