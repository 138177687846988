<template>
    <v-container>
        <v-row>
            <v-col v-for="(item, idx) in sortedAttrItems" :key="idx" cols="12">
                <attr-card
                    :editable="canEdit"
                    :total-weight="totalWeight"
                    :attr="item.attr"
                    :original-attr="item.originalAttr"
                    :curve-editable="canEdit"
                    :show-apply-project="canEdit"
                    @input="_updateAttr(item.idx, $event)"
                    @apply="_applyAttr(item.idx)"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Vue from "vue";
    import {store} from "@/store";
    import {isLinked} from "./ds";
    import {applyDesignStudyAttributeProjectWide, editDesignStudyAttribute} from "@/model/ops";

    import map from 'lodash/map';
    import filter from 'lodash/filter';
    import sortBy from 'lodash/sortBy';
    import fromPairs from 'lodash/fromPairs';

    import attrCard from './../attr-card.vue';

    export default Vue.extend({
        name: "ds-attrs",
        components: {
            attrCard,
        },
        props: {
            designStudy: {required: true},
        },
        computed: {
            project: () => store.state.localProject,
            editable: () => store.state.editable,
            canEdit() {
                return this.editable && store.state.settings.edit_ds;
            },
            designStudyId() {
                return this.designStudy.id;
            },

            attrItems() {
                const attrMap = fromPairs(map(this.project.attributes, (attr) => [attr.id, attr]));
                return filter(map(this.designStudy.attributes, (attr, idx) => {
                    if (!isLinked(attr) || !attrMap[attr.attributeId]) return null;
                    return {
                        idx,
                        attr,
                        originalAttr: attrMap[attr.attributeId],
                    };
                }));
            },
            sortedAttrItems() {
                return sortBy(this.attrItems, 'attr', 'name');
            },
            totalWeight() {
                let totalWeight = 0;
                for (const item of this.attrItems) {
                    let weight = item.attr.weight;
                    if (weight === null || weight === undefined) weight = item.originalAttr.weight;
                    if (weight === null || weight === undefined) weight = 0;
                    totalWeight += weight;
                }
                return totalWeight;
            },
        },
        methods: {
            _updateAttr(idx, editedAttr) {
                editDesignStudyAttribute(this.designStudyId, idx, (attr) => {
                    attr.weight = editedAttr.weight;

                    attr.utilityFunctionPath = editedAttr.utilityFunctionPath;
                    attr.bounds = editedAttr.bounds;
                    attr.boundsZeroUtility = editedAttr.boundsZeroUtility;
                });
            },
            _applyAttr(idx) {
                applyDesignStudyAttributeProjectWide(this.designStudyId, idx);
            },
        },
    });
</script>

<style scoped>

</style>