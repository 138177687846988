<template>
    <div>
        <!-- Login modal -->
        <v-dialog
            v-model="loginDisplay"
            persistent
            transition=""
            overlay-color="white"
            overlay-opacity="1"
            max-width="600"
        >
            <v-card :loading="loading">
                <v-card-title class="headline">{{ loginTitle }}</v-card-title>

                <v-card-text>
                    <v-form v-model="loginFormValid" @submit.prevent="" ref="loginForm">

                        <!-- Username -->
                        <v-text-field
                            v-if="_hasLoginField('username')"
                            v-model="loginData.username"
                            label="Username"
                            hide-details="auto"
                            :disabled="loading"
                            @keyup.enter="_doLogin"
                            :rules="[rules.required]"
                            ref="usernameField"
                        />

                        <!-- Password -->
                        <v-text-field
                            v-if="_hasLoginField('password')"
                            v-model="loginData.password"
                            type="password"
                            label="Password"
                            hide-details="auto"
                            :disabled="loading"
                            @keyup.enter="_doLogin"
                            :rules="[rules.required]"
                        />

                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-btn text @click="_displayAbout" v-if="hasLegalText" :disabled="loading">
                        About {{ (hasLegalText) ? '- Legal - Impressum': '' }}
                    </v-btn>

                    <v-spacer />

                    <v-btn text color="primary" @click="_doLogin" :disabled="loading">Login</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Scope select modal -->
        <v-dialog
            v-model="scopeDisplay"
            persistent
            transition=""
            overlay-color="white"
            overlay-opacity="1"
            max-width="600"
        >
            <v-card :loading="loading">
                <v-card-title class="headline">{{ scopeSelectTitle }}</v-card-title>

                <v-card-text>
                    <v-form v-model="scopeFormValid" @submit.prevent="" ref="scopeForm">

                        <v-select
                                v-model="selectedScopeKey"
                                :items="scopeItems"
                                :label="'Select a '+scopeName"
                                :rules="[rules.required]"
                                :disabled="loading"
                                ref="scopeSelect"
                        />

                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="primary" @click="_doScopeSelect" :disabled="loading">{{ scopeSelectTitle }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {api, dispatcher} from "@/main";
    import {store} from '@/store';

    import includes from 'lodash/includes';
    import map from "lodash/map";
    import Vue from "vue";

    export default Vue.extend({
        name: "auth",
        props: {
            showing: {default: false},
        },
        data: () => ({
            scopes: [],
            selectedScopeKey: null,
            loginData: {},
            loading: false,

            loginFormValid: true,
            scopeFormValid: true,
            loginDisplay: false,
            scopeDisplay: false,

            rules: {
                required: (value) => !!value || 'Required',
            },
        }),
        watch: {
            isAuth() {
                this._checkAuth();
            },
            hasScope() {
                this._checkAuth();
            },
            hasLoaded() {
                this._checkAuth();
            },
            showingModal(showingModal) {
                this.$emit('update:showing', showingModal);
            },
            isCompleteAuth(isCompleteAuth) {
                if (isCompleteAuth) store.dispatch.updateBackendState();
            },
        },
        computed: {
            remoteName: () => store.state.settings.remote_name,
            hasLegalText: () => store.state.settings.legal_text_markdown !== undefined,
            loginFields: () => store.state.settings.login_fields || [],

            hasLoaded: () => store.state.hasLoaded,
            isAuth: () => store.state.isAuth,
            hasSelectedScope: () => store.getters.hasSelectedScope,
            isCompleteAuth() {
                return this.isAuth && this.hasSelectedScope;
            },
            selectedScope: () => store.state.selectedScope,

            scopeName: () => store.state.settings.scope_name || '',
            scopeSelectTitle() {
                return 'Select '+this.scopeName;
            },
            loginTitle() {
                let title = 'Login';
                if (this.remoteName) title += ' to '+this.remoteName;
                return title;
            },
            scopeItems() {
                return map(this.scopes, ([scopeKey, scopeName]) => ({ value: scopeKey, text: scopeName }));
            },
        },
        methods: {
            _showLogin() {
                this._resetLoginForm();
                this.loginDisplay = true;

                this.loginFormValid = true;
                if (this.$refs.loginForm) this.$refs.loginForm.resetValidation();

                setTimeout(() => {
                    if (this.$refs.usernameField) this.$refs.usernameField.focus();
                }, 500);
            },
            _hasLoginField(field) {
                return includes(this.loginFields, field);
            },
            _resetLoginForm() {
                this.loginData = {
                    username: '',
                    password: '',
                };
            },
            _doLogin() {
                this.$refs.loginForm.validate();
                this.$nextTick(() => {
                    if (!this.$refs.loginForm.validate()) return;
                    if (!this.loginFormValid) {
                        this._resetLoginForm();
                        return;
                    }

                    api.login(this.loginData, () => {
                        if (this.hasSelectedScope) {
                            this._hideLogin();
                            this._refresh();
                        }
                    }, () => {
                        this._resetLoginForm();
                    });
                });
            },
            _hideLogin() {
                this.loginDisplay = false;
                this._resetLoginForm();
            },

            _showScopeSelect() {
                this.scopeFormValid = true;
                if (this.$refs.scopeForm) this.$refs.scopeForm.resetValidation();

                this.scopes = [];
                this.selectedScopeKey = null;
                api.listScopes((scopes) => {
                    this.scopes = scopes;
                    this.scopeDisplay = true;
                    this._hideLogin();
                    if (this.$refs.scopeSelect) this.$refs.scopeSelect.focus();
                });
            },
            _doScopeSelect() {
                this.$refs.scopeForm.validate();
                this.$nextTick(() => {
                    if (!this.$refs.scopeForm.validate()) return;
                    if (!this.scopeFormValid) return;

                    api.selectScope(this.selectedScopeKey, () => {
                        if (this.hasSelectedScope) {
                            this._hideScopeSelect();
                            this._refresh();
                        }
                    });
                });
            },
            _hideScopeSelect() {
                this.scopeDisplay = false;
            },

            _refresh() {
                store.dispatch.updateBackendState();
            },
            _checkAuth() {
                if (!this.isAuth) {
                    this._showLogin();
                } else if (!this.hasSelectedScope) {
                    this._showScopeSelect();
                } else {
                    this._hideLogin();
                    this._hideScopeSelect();
                }
            },

            _displayAbout() {
                dispatcher.displayAbout();
            },
            _isLoading(loading) {
                this.loading = loading;
            },
        },
        mounted() {
            dispatcher.onIsLoading(this._isLoading);

            setTimeout(() => this._checkAuth(), 100);
        },
    });
</script>

<style scoped>

</style>