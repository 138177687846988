<template>
    <div class="fill-height">
        <div class="fill-height d-flex align-stretch">
            <!-- Left pane -->
            <div class="col-12 pa-0 d-flex flex-column">
                <div class="flex-grow-0">
                    <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"
                    />
                </div>
                <div class="flex-grow-1 ds-table" ref="tableWrapper" v-resize="_updateHeight">
                    <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="items"
                        :search="search"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        item-key="idx"
                        item-class="class"
                        single-select
                        dense fixed-header
                        disable-pagination hide-default-footer
                        :height="height"
                        @click:row="_rowClick"
                    >
                        <template v-slot:header>
                            <thead>
                            <tr>
                                <th v-for="mh in multiHeaders"
                                    :colspan="mh.col"
                                    class="text-center">
                                    <div>{{ mh.text }}</div>
                                    <div v-if="mh.weight">wt: {{ mh.relWeight }}%</div>
                                </th>
                            </tr>
                            </thead>
                        </template>

                        <template v-slot:no-data>
                            <span v-if="loading">
                                Loading data
                                <v-progress-circular indeterminate size="24" class="my-2 mx-1" :color="valDark" />
                            </span>
                            <span v-else>No data available</span>
                        </template>

                        <!-- Correctly format attribute raw content -->
                        <template v-for="{slot, name} in attrItemSlots" v-slot:[slot]="{ item }">{{ item[name+'_disp'] }}</template>
                    </v-data-table>
                </div>
            </div>

            <!--<v-divider vertical />-->

            <!-- Design point pane -->
            <!--<div class="col-3 pa-0 scroll">
                <v-container fluid v-if="selectedIdx !== null">
                    <h2 class="text-center">Design Point {{ selectedIdx+1 }}</h2>
                    <h3 v-if="selectedPoint.ref" class="text-center">{{ selectedPoint.ref }}</h3>

                    <v-simple-table dense class="mt-6">
                        <thead>
                            <tr>
                                <th>Attribute</th>
                                <th>Weight</th>
                                <th>Content</th>
                                <th>Utility</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(ptAttr, idx) in selectedPointLinkedAttr">
                                <td>{{ linkedAttr[idx].name }}</td>
                                <td>{{ _fmt((totalWeight === 0) ? 0: 100*(ptAttr.weight/totalWeight), 1) }}%</td>
                                <td>{{ _formatRawContent(ptAttr.rawContent, idx) }}</td>
                                <td>{{ _fmt(ptAttr.utility, 2) }}</td>
                            </tr>
                            <tr
                                v-if="selectedPoint.value !== undefined && selectedPoint.value !== null"
                                class="font-weight-bold">
                                <td colspan="2"></td>
                                <td class="text-right">Value:</td>
                                <td>{{ _fmt(selectedPoint.value, 2) }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>

                </v-container>
            </div>-->
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import {store} from "@/store";
    import {fmt, parseBounds, getNrDigits} from "@/comp/utility-curve";
    import {getDesignPointAttrWeight} from "@/model/ops";
    import {valDark} from "@/main";
    import {linkedAttr, linkedDesignPointAttributes} from "./ds";

    import map from "lodash/map";
    import sum from "lodash/sum";
    import concat from "lodash/concat";
    import fromPairs from "lodash/fromPairs";

    export default Vue.extend({
        name: "ds-table",
        props: {
            designStudy: {required: true},
            loading: {default: false},
        },
        data: () => ({
            valDark,

            selected: [],
            search: '',
            sortBy: 'value',
            sortDesc: true,
            height: 500,
        }),
        computed: {
            project: () => store.state.localProject,
            editable: () => store.state.editable,
            canEdit() {
                return this.editable && store.state.settings.edit_ds;
            },

            projectAttr() {
                return this.project.attributes;
            },
            projectAttrMap() {
                return fromPairs(map(this.projectAttr, (attr) => [attr.id, attr]));
            },
            attr() {
                return this.designStudy.attributes;
            },
            linkedAttr() {
                return linkedAttr(this.designStudy);
            },
            attrWeights() {
                const projectAttrMap = this.projectAttrMap;
                return map(this.linkedAttr, (attr) => {
                    const projectAttr = (attr.attributeId in projectAttrMap) ? projectAttrMap[attr.attributeId]: null;
                    return getDesignPointAttrWeight(attr, projectAttr);
                });
            },
            totalWeight() {
                return sum(this.attrWeights);
            },
            attrItemSlots() {
                return map(this.linkedAttr, (attr) => ({slot: 'item.'+attr.name, name: attr.name}));
            },

            multiHeaders() {
                const attrWeights = this.attrWeights;
                const totalWeight = this.totalWeight;

                return concat(
                    [{ col: 2 }],
                    map(this.linkedAttr, (attr, idx) => ({
                        text: attr.name,
                        weight: fmt(attrWeights[idx], 2),
                        relWeight: fmt((totalWeight === 0) ? 0: 100*(attrWeights[idx]/totalWeight), 1),
                        col: 2,
                    })),
                )
            },
            headers() {
                const attrHeaders = [];
                for (const attr of this.linkedAttr) {
                    attrHeaders.push({ text: 'Content', value: attr.name, align: 'center' });
                    attrHeaders.push({ text: 'Utility', value: attr.name+'_util', align: 'center' });
                }

                return concat([
                    { text: '#', value: 'idx', align: 'center' },
                    // { text: 'Ref.', value: 'ref', align: 'center' },
                    { text: 'Value', value: 'value', align: 'center' },
                ], attrHeaders)
            },
            attrRawContentNrDigits() {
                return fromPairs(map(this.projectAttr, (attr) => {
                    const bounds = attr.bounds;
                    const nrDigits = (bounds !== undefined && bounds !== null) ? getNrDigits(parseBounds(bounds)): 2;
                    return [attr.id, nrDigits];
                }));
            },
            designPoints() {
                const dp = store.state.designPoints[this.designStudy.id];
                return (dp === undefined) ? []: dp;
            },
            designPointsLinked() {
                return linkedDesignPointAttributes(this.designStudy, this.designPoints);
            },
            items() {
                const bestValueIdx = this.designStudy.bestValueIdx;
                const attr = this.linkedAttr;

                return map(this.designPointsLinked, (dp, idx) => {
                    const isBest = idx === bestValueIdx;

                    const item = {
                        idx: idx+1,
                        ref: dp.ref || '',
                        value: fmt(dp.value, 4),
                        valueNum: (dp.value !== undefined && dp.value !== null) ? dp.value: 0,
                        class: (isBest) ? 'green lighten-4': '',
                    };

                    for (let i = 0; i < attr.length; i++) {
                        const name = attr[i].name;
                        const content = dp.attributes[i];
                        item[name] = content.rawContent;
                        item[name+'_disp'] = this._formatRawContent(content.rawContent, i);
                        item[name+'_util'] = fmt(content.utility, 2);
                        item[name+'_util_num'] = (content.utility !== undefined && content.utility !== null) ?
                            content.utility: 0;
                    }

                    return item;
                });
            },

            selectedIdx() {
                return (this.selected.length === 0) ? null: this.selected[0].idx-1;
            },
            selectedPoint() {
                return (this.selectedIdx !== null) ? this.designPointsLinked[this.selectedIdx]: null;
            },
            selectedPointLinkedAttr() {
                const selectedPoint = this.selectedPoint;
                return (selectedPoint === null) ? []: selectedPoint.attributes;
            },
        },
        methods: {
            _updateHeight() {
                if (!this.$refs.tableWrapper) return;
                this.height = this.$refs.tableWrapper.clientHeight;
            },
            _rowClick(item, data) {
                if (data.isSelected) {
                    data.select(null);
                } else {
                    data.select(item.idx);
                }
            },
            _fmt(val, digits) {
                return fmt(val, digits);
            },
            _formatRawContent(rawContent, idx) {
                const attr = this.linkedAttr;
                const nrDigits = this.attrRawContentNrDigits;
                const d = (attr[idx].attributeId in nrDigits) ? nrDigits[attr[idx].attributeId]: 2;
                return (typeof rawContent === 'string') ? rawContent: fmt(rawContent, d);
            },
        },
        mounted() {
            this.$nextTick(() => this._updateHeight());
        },
    });
</script>

<style scoped>
    .ds-table {
        position: relative;
    }
    .ds-table >>> tbody tr {
        cursor: pointer;
    }
    .ds-table >>> th.sortable span.v-icon {
        margin-right: -18px;
    }
</style>