import Vue from 'vue';
import Vuex from 'vuex';
import * as m from './model/model';
import {api, dispatcher} from "./main";
import {BackendState, ProjectResponseCallback} from "./api";
import {updateDesignStudy} from "@/model/ops";

import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';

import {createDirectStore} from "direct-vuex";
import {DesignPoint} from "./model/model";
Vue.use(Vuex);

export interface SettingsObject {
    version: string,
    namespace?: string,

    new_project: boolean,
    load_project: boolean,
    save_project: boolean,
    save_project_as: boolean,
    delete_project: boolean,
    import_project: boolean,
    has_project_list: boolean,
    has_backend_ui: boolean,

    edit_refs: boolean,
    edit_attr: boolean,
    import_attr: boolean,
    edit_ds: boolean,
    import_ds: boolean,

    db_provides_attr: boolean,
    db_provides_ds: boolean,

    has_auth: boolean,
    login_fields?: string[],
    scope_name?: string,
    remote_name?: string,
    display_selected_scope: boolean,

    legal_text_markdown?: string,
    logo_files: string[],
}

export interface RootState {
    settings: SettingsObject,
    project: null|m.Project,
    localProject: null|m.Project,
    designPoints: { [key: string] : m.DesignPoint[]},
    backendState: BackendState,
    localNextId: m.idType,
    editable: boolean,

    isAuth: boolean|string,
    selectedScope: string|null,
    hasLoaded: boolean,
}

const {store, rootActionContext, moduleActionContext} = createDirectStore({
    strict: true,
    state: {
        settings: {},
        project: null,
        localProject: null,
        designPoints: {},
        backendState: {
            persists: true,
            hasUndo: false,
            hasRedo: false,
            nextId: 1,
        },
        localNextId: 1,
        editable: true,

        isAuth: false,
        selectedScope: '',
        hasLoaded: false,
    } as RootState,

    getters: {
        hasSelectedScope(state): boolean {
            return state.selectedScope !== null;
        },
        authUsername(state): string {
            return (state.isAuth === true) ? '': (state.isAuth || '');
        },
    },

    mutations: {
        commitProject(state: RootState, {project, response}: {project: m.Project, response: BackendState}) {
            state.project = project;
            state.localProject = project;
            state.backendState = response;
            state.localNextId = response.nextId;

            state.hasLoaded = true;
        },
        commitBackendState(state: RootState, response: BackendState) {
            state.backendState = response;
            state.localNextId = response.nextId;
        },
        commitOverrideNextId(state: RootState, nextId: m.idType) {
            state.localNextId = nextId;
        },
        incrementNextId(state: RootState) {
            state.localNextId++;
        },
        commitSettings(state: RootState, settings: SettingsObject) {
            state.settings = settings;
        },
        commitLocalProject(state: RootState, project: m.Project) {
            state.localProject = project;
            state.backendState.persists = false;
        },
        commitAuthState(state: RootState, {isAuth, selectedScope}: {isAuth: boolean|string, selectedScope: string|null}) {
            if (isAuth !== state.isAuth || selectedScope !== state.selectedScope) {
                state.isAuth = isAuth;
                state.selectedScope = selectedScope;
            }
        },
        commitInvalidateDPState(state: RootState, designStudyIds?: m.idType[]|null) {
            let invalidatedIds: number[] = [];

            if (!state.project) return;
            const project = cloneDeep(state.project);
            const designPoints = cloneDeep(state.designPoints);

            for (const ds of (project.designStudies || [])) {
                if (designStudyIds && designStudyIds.indexOf(ds.id) === -1) continue;

                if (ds.id in designPoints) {
                    invalidatedIds.push(ds.id);
                    updateDesignStudy(project.attributes, ds, designPoints[ds.id]);
                }
            }

            if (invalidatedIds.length > 0) {
                state.localProject = project;
                state.designPoints = designPoints;

                for (const dsId of invalidatedIds) {
                    dispatcher.resultsStateInvalidated(dsId);
                }
            }
        },
        commitDesignPointsState(state: RootState, {dsId, designPoints}: {dsId: m.idType, designPoints: DesignPoint[]}) {
            Vue.set(state.designPoints, dsId, designPoints);
        },
    },

    actions: {
        updateBackendState(context) {
            const { commit } = rootActionContext(context);
            api.getProject((project, response) => {
                commit.commitProject({project, response});
                commit.commitInvalidateDPState();
            });
        },
        setProject(context, {project, callback, influencesResults}:
                {project: m.Project, callback?: (project: m.Project) => void, influencesResults?: m.idType[]|boolean}) {
            const { commit } = rootActionContext(context);
            commit.commitLocalProject(project);
            api.setProject(project, (project, response) => {
                commit.commitProject({project, response});
                if (influencesResults) {
                    commit.commitInvalidateDPState((influencesResults === true) ? null: influencesResults);
                }
                if (callback) callback(project);
            });
        },
        loadDesignPoints(context, {dsId, callback}: {dsId: m.idType, callback?: (designPoints: m.DesignPoint[]) => void}) {
            const { commit } = rootActionContext(context);
            api.loadDesignStudyPoints(dsId, (designPoints => {
                commit.commitDesignPointsState({dsId, designPoints});
                if (callback) callback(designPoints);
            }));
        },

        setProjectName(context, name: string) {
            const { state, dispatch } = rootActionContext(context);
            dispatch.setProject({project: assign({}, state.project, {name})});
        },
    },
});

export default store;
export {
    store,
    rootActionContext,
    moduleActionContext,
}

export const moduleGetterContext = <T>(args: [any, any, any, any]):
    { state: T, rootState: RootState } => ({ state: args[0], rootState: args[2] });

export const updatedProject: ProjectResponseCallback = (project, response) => {
    store.commit.commitProject({project, response});
};
export const editProject = (edit: (project: m.Project) => void, project?: m.Project, clone: boolean = true,
                            set: boolean = true, callback?: (project: m.Project) => void,
                            influencesResults?: m.idType[]|boolean) => {
    if (project === undefined) {
        if (store.state.localProject === null) throw new Error('No project set');
        project = store.state.localProject;
    }
    if (clone) project = cloneDeep(project);
    edit(project);
    if (set) store.dispatch.setProject({project, callback, influencesResults});
    return project;
};
export const getNextId = (): m.idType => {
    const nextId = store.state.localNextId;
    store.commit.incrementNextId();
    return nextId;
};
export const loadDesignPointsIfNeeded = (dsId: m.idType, callback?: (designPoints: m.DesignPoint[]) => void,
                                         force: boolean = false) => {
    if (force || !(dsId in store.state.designPoints)) {
        store.dispatch.loadDesignPoints({dsId, callback});
    } else {
        if (callback) callback(store.state.designPoints[dsId]);
    }
};
