import svg from 'svg-intersections';
import pathBounds from 'svg-path-bounds';

export type boundsType = [number, number, number, number];
export type boundsZeroUtilityType = [boolean, boolean];

/**
 * Calculate the value of a design point given utilities and weights.
 * Should have same logic as model/utility.py::UtilityCalc._calc_value!
 */
export function calcValue(utilities: number[], weights: number[]): number {
    let sum = 0;
    let weightsSum = 0;
    for (let i = 0; i < utilities.length; i++) {
        sum += utilities[i]*weights[i];
        weightsSum += weights[i];
        // if (isNaN(utilities[i])) return NaN;
    }
    if (weightsSum === 0) throw new Error('Division by zero!');
    return sum/weightsSum;
}

/**
 * Calculates the bounds of a utility curve.
 * Should have same logic as model/utility.py::UtilityCalc._calc_bounds!
 */
export function calcBounds(path: string): [boundsType, boundsZeroUtilityType] {

    // Get bounding box
    let [xMin, uMin, xMax, uMax] = pathBounds(path);

    // Check for invalid vertical curve (might happen during editing)
    if (xMax === xMin) {
        xMax += .01;
        return [[xMin, xMax, uMin, uMax], [true, true]];
    }

    // Find utility at bounds
    let boundsZeroUtility: boundsZeroUtilityType = [true, true];
    if (uMin !== uMax) {
        const x = [xMin, xMax];
        const dx = [.001, -.001];
        for (let i = 0; i < 2; i++) {
            const attrInputPath = `M ${x[i]+dx[i]} ${uMin} L ${x[i]+dx[i]} ${uMax}`;

            const intersections = svg.intersect(
                svg.shape('path', {d: attrInputPath}),
                svg.shape('path', {d: path}),
            )
            if (intersections.points.length === 0) throw new Error('No intersection found!');

            const utilityMin = intersections.points[0].y;
            if (utilityMin > .5) boundsZeroUtility[i] = false;
        }
    }

    return [[xMin, xMax, uMin, uMax], boundsZeroUtility];
}

/**
 * Calculates the utility given some attribute value and utility curve.
 * Should have same logic as model/utility.py::UtilityCalc._calc_utility!
 */
export function calcUtility(path: string, x: number, bounds: boundsType, boundsU0: boundsZeroUtilityType) {

    // Check invalid value
    if (isNaN(x)) return NaN;

    // Detect outside bounds
    if (bounds[0] > bounds[1]) throw new Error(`Malformed bounds: ${bounds}`);
    if (x < bounds[0]) return (boundsU0[0]) ? 0: 1;
    if (x > bounds[1]) return (boundsU0[1]) ? 0: 1;

    // Find intersection between straight vertical line at x and the utility curve path
    if (bounds[2] === bounds[3]) return bounds[2];
    const attrInputPath = `M ${x} ${bounds[2]} L ${x} ${bounds[3]}`;

    const intersections = svg.intersect(
        svg.shape('path', {d: attrInputPath}),
        svg.shape('path', {d: path}),
    )

    // If no intersection is found, attribute value is outside the bounds
    if (intersections.points.length === 0) {
        const boundsMid = .5*(bounds[0]+bounds[1]);
        const isZeroUtility = (x < boundsMid) ? boundsU0[0]: boundsU0[1];
        return (isZeroUtility) ? 0: 1;
    }

    // Utility is the y value on the attr line
    const utility = intersections.points[0].y;
    return Math.max(0, Math.min(1, utility));
}
