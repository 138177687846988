import {SVGPathData, encodeSVGPath} from 'svg-pathdata';
import {CommandC, CommandL, CommandQ, SVGCommand} from "svg-pathdata/lib/types";
import {linearInterpolation} from "simple-linear-interpolation";

import cloneDeep from 'lodash/cloneDeep';

export type Commands = SVGCommand[];

export const defaultPath: Commands = [
    { type: SVGPathData.MOVE_TO, relative: false, x: 0, y: 0 },
    { type: SVGPathData.LINE_TO, relative: false, x: 1, y: 1 },
];

export const cmdTypes = [
    SVGPathData.MOVE_TO,
    SVGPathData.LINE_TO,
    SVGPathData.QUAD_TO,
    SVGPathData.CURVE_TO,
];

export const typeTitles = {
    [SVGPathData.MOVE_TO]: 'Origin',
    [SVGPathData.LINE_TO]: 'Linear',
    [SVGPathData.QUAD_TO]: 'Curve (1 pt)',
    [SVGPathData.CURVE_TO]: 'Curve (2 pts)',
};

export const defaultLinearPath = defaultPath;

export function parsePath(path: string): Commands {
    return (new SVGPathData(path)).commands;
}

export function renderCommands(commands: Commands): string {
    return encodeSVGPath(commands);
}

export interface Bounds {
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
}

export function parseBounds(bounds: [number, number, number, number], constrainUtility: boolean = true): Bounds {
    return {
        xMin: bounds[0],
        xMax: bounds[1],
        yMin: (constrainUtility) ? 0: bounds[0],
        yMax: (constrainUtility) ? 1: bounds[1],
    };
}

export function getBounds(commands: Commands, constrainUtility: boolean = true): Bounds {
    const pathData = new SVGPathData(commands);
    const bounds = pathData.getBounds();
    return {
        xMin: bounds.minX,
        xMax: bounds.maxX,
        yMin: (constrainUtility) ? 0: bounds.minY,
        yMax: (constrainUtility) ? 1: bounds.maxY,
    }
}

export function roundUtil(value: number, restrict = true): number {
    if (restrict) value = Math.max(0, Math.min(1, value));
    return Math.round(value*100)/100;
}

export function translateScale(commands: Commands, source: Bounds, target: Bounds): Commands {
    const pathData = new SVGPathData(cloneDeep(commands));
    pathData.translate(-source.xMin, -source.yMin);
    pathData.scale((target.xMax-target.xMin)/(source.xMax-source.xMin),
        (target.yMax-target.yMin)/(source.yMax-source.yMin));
    pathData.translate(target.xMin, target.yMin);
    return pathData.commands;
}

type SVGPoint = { x: number, y: number };
type SVGCommandXY = SVGCommand & SVGPoint;
export function interpolateCommands(srcCommand: SVGCommand, tgtCommand: SVGCommand, s: number = .5): CommandL {
    const {x: x1, y: y1} = (srcCommand as SVGCommandXY);
    const {x: x2, y: y2} = (tgtCommand as SVGCommandXY);
    const interp = linearInterpolation([{x: x1, y: y1}, {x: x2, y: y2}]);

    const xInterp = x1+s*(x2-x1);
    const yInterp = interp({x: xInterp});
    return {
        type: SVGPathData.LINE_TO,
        relative: false,
        x: xInterp,
        y: yInterp,
    };
}

export function getControlPoints(commands: Commands): SVGPoint[] {
    const points: SVGPoint[] = [];
    let x = 0;
    let y = 0;
    for (const cmd of commands) {
        if ('x' in cmd) x = cmd.x;
        if ('y' in cmd) y = cmd.y;
        points.push({ x, y });
    }
    return points;
}

export function getCurveControlPoints(commands: Commands): SVGPoint[] {
    const points: SVGPoint[] = [];
    let x = 0;
    let y = 0;
    for (const cmd of commands) {
        const curveCommand = cmd as CommandC;

        if ('x1' in cmd) {
            points.push({ x, y });
            points.push({ x: curveCommand.x1, y: curveCommand.y1 });
        }

        if ('x' in cmd) {
            x = curveCommand.x;
            y = curveCommand.y;
        }

        if ('x2' in cmd) { // Cubic Bezier (2 control points)
            points.push({ x, y });
            points.push({ x: curveCommand.x2, y: curveCommand.y2 });
        } else if ('x1' in cmd) { // Quadratic Bezier (1 control point)
            points.push({ x, y });
            points.push({ x: curveCommand.x1, y: curveCommand.y1 });
        }
    }
    return points;
}

export function setCommandType(commands: Commands, idx: number, type: number): Commands {
    const newCommands = cloneDeep(commands);
    if (idx === 0) {
        if (type !== SVGPathData.MOVE_TO) throw Error('First command must be MOVE_TO!');
    } else {
        if (type === SVGPathData.LINE_TO) {
            // No need to do anything else

        } else if (type === SVGPathData.QUAD_TO) {
            if (newCommands[idx].type !== SVGPathData.CURVE_TO) {
                // Determine initial control point location
                const cmdInterp = interpolateCommands(newCommands[idx-1], newCommands[idx]);

                const cmd = newCommands[idx] as CommandQ;
                cmd.x1 = cmdInterp.x;
                cmd.y1 = cmdInterp.y;
                newCommands[idx] = cmd;
            }

        } else if (type === SVGPathData.CURVE_TO) {
            // Determine initial control point locations
            const cmdInterp1 = interpolateCommands(newCommands[idx-1], newCommands[idx], .33);
            const cmdInterp2 = interpolateCommands(newCommands[idx-1], newCommands[idx], .67);

            const cmd = newCommands[idx] as CommandC;
            if (newCommands[idx].type === SVGPathData.QUAD_TO) {
                const oldCmd = newCommands[idx] as CommandQ;
                cmd.x1 = oldCmd.x1;
                cmd.y1 = oldCmd.y1;
            } else {
                cmd.x1 = cmdInterp1.x;
                cmd.y1 = cmdInterp1.y;
            }
            cmd.x2 = cmdInterp2.x;
            cmd.y2 = cmdInterp2.y;
            newCommands[idx] = cmd;

        } else {
            throw Error(`Unknown SVG command type: ${type}`);
        }
    }
    newCommands[idx].type = type;

    return parsePath(renderCommands(newCommands));
}

export function getNrDigits(bounds: Bounds): number {
    return Math.max(0, -Math.floor(Math.log10(Math.abs(.5*(bounds.xMax+bounds.xMin))))+2);
}
export function fmt(val: number|null|undefined, n: number): string {
    if (val === null || val === undefined) return '';
    if (isNaN(val)) return '-';
    return val.toLocaleString('en-US', { minimumFractionDigits: n, maximumFractionDigits: n })
        .replace(/,/g, ' ');
}
