import { Manager, Socket } from 'socket.io-client';
import {dispatcher} from "./main";
import * as m from "./model/model";
import b64ToBlob from 'b64-to-blob';
import pako from 'pako';
import Cookies from 'js-cookie';
import {parse, stringify, stripVueProps} from './json-parser';
import {SettingsObject} from "@/store";

import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import isUndefined from 'lodash/isUndefined';
import blobToBase64 from "blob-to-base64";

export interface LoginData {
    username?: string,
    password?: string,
}

interface ReturnData {
    success: boolean,
    msg?: string,
    data?: any,
    comp?: boolean,
    auth: boolean|string,
    scope: string|null,
}

export interface FileContent {
    filename: string,
    data: string, // base64 encoded
    mimeType: string,
}
type FileContentCallback = (fileContent: FileContent) => void;

export function base64Encode(blob: Blob, callback: (data: string) => void): void {
    blobToBase64(blob, (err, base64Data) => {
        if (err) {
            console.log(err);
            return;
        }
        if (!base64Data) return;

        // base64 string starts with: data:...;base64,...
        base64Data = base64Data.split(';base64,', 2)[1];
        callback(base64Data);
    });
}

export interface ProjectResponse {
    json: string,
    state: BackendState,
}
export interface BackendState {
    persists: boolean,
    hasUndo: boolean,
    hasRedo: boolean,
    nextId: m.idType,
}
export type ProjectResponseCallback = (project: m.Project, response: BackendState) => void;
export type ProjectListResponseCallback = (projects: {ref: string, name: string, created: string, updated: string}[]) => void;

function projectResponseCallback(callback?: ProjectResponseCallback) {
    return (response: ProjectResponse) => {
        if (callback) callback(parse(response.json), response.state);
    }
}

export type EmptyCallback = () => void;
export type ErrorCallback = (d: ReturnData) => void;
export type ScopeListCallback = (scopes: [string, string][]) => void;

// Dispatcher is not yet instantiated
let debouncedLoading = debounce((_) => {}, 0);

export default class Api {
    socket: Socket;
    routeNamespace?: string;
    oldSession?: string;
    loadingEvents: [string, Date][];
    loadingTimeout: number = 10;

    constructor(settings: SettingsObject) {
        this.loadingEvents = [];
        this.routeNamespace = settings.namespace;

        this.oldSession = Cookies.get('io');
        const namespace = (this.routeNamespace) ? '/'+this.routeNamespace: '';
        const manager = new Manager(window.location.protocol+'//'+document.domain+':'+location.port, {
            path: namespace+'/socket.io/',
        });
        this.socket = manager.socket('/');

        // Migrate backend session
        dispatcher.isLoading(true);
        this.socket.on('connect', () => {
            const sessionId = this.sessionId();
            if (this.oldSession && sessionId !== this.oldSession) {
                this.migrateSession(this.oldSession);
            } else {
                dispatcher.updatedBackendState();
            }
            Cookies.set('io', sessionId, { expires: 1, sameSite: 'strict' });
        });

        // Only at this point the dispatcher has been instantiated
        debouncedLoading = debounce(dispatcher.isLoading, 500);

        this.startKeepalive(2000);
        setInterval(() => this.checkFinishedLoading(), 1000);
    }

    migrateSession(oldSessionId: string) {
        this.call('migrate-session', oldSessionId, () => {
            this.oldSession = this.sessionId();
            dispatcher.isLoading(true);
            dispatcher.updatedBackendState();
            dispatcher.fileOps();
        });
    };

    sessionId() {
        let sid = this.socket.id;
        if (sid[0] === '/') sid = sid.split('#')[1];
        return sid;
    }

    login(data: LoginData, callback?: EmptyCallback, errorCallback?: ErrorCallback) {
        this.call('login', data, callback, errorCallback);
    }

    logout(callback?: EmptyCallback) {
        this.call('logout', null, callback);
    }

    listScopes(callback: ScopeListCallback) {
        this.call('list-scopes', null, callback);
    }

    selectScope(scopeKey: string, callback?: EmptyCallback) {
        this.call('select-scope', scopeKey, callback);
    }

    /**
     * Get the current active project.
     */
    getProject(callback?: ProjectResponseCallback) {
        this.call('get-project', null, projectResponseCallback(callback));
    }

    /**
     * Update the current active project.
     */
    setProject(project: m.Project, callback?: ProjectResponseCallback) {
        this.call('set-project', this.serializeProject(project), projectResponseCallback(callback));
    }

    serializeProject(project: m.Project): string {
        return stringify(stripVueProps(project));
    }

    /**
     * Initialize a new project.
     */
    newProject(callback?: ProjectResponseCallback) {
        this.call('new-project', null, projectResponseCallback(callback));
    }

    /**
     * List available projects.
     */
    listProjects(callback?: ProjectListResponseCallback) {
        this.call('list-projects', null, callback);
    }

    /**
     * Add a project (from file) to the project list.
     */
    importProject(data: string, callback?: ProjectListResponseCallback) {
        this.call('import-project', data, callback);
    }

    /**
     * Select a project from available projects.
     */
    selectProject(ref: string, callback?: ProjectResponseCallback) {
        this.call('select-project', ref, projectResponseCallback(callback));
    }

    /**
     * Delete a project from available projects.
     */
    deleteProject(ref: string, callback?: ProjectResponseCallback) {
        this.call('delete-project', ref, projectResponseCallback(callback));
    }

    /**
     * Load a project from disk (backend asks for file location).
     */
    loadProject(callback?: ProjectResponseCallback) {
        this.call('load-project', null, projectResponseCallback(callback));
    }

    /**
     * Upload data from a project file to be used as the current project.
     */
    uploadProject(data: string, callback?: ProjectResponseCallback) {
        this.call('upload-project', data, projectResponseCallback(callback));
    }

    /**
     * Save the project to the currently used location.
     */
    saveProject(callback?: ProjectResponseCallback) {
        this.call('save-project', null, projectResponseCallback(callback));
    }

    /**
     * Save the project to a new location (backend asks for file location).
     */
    saveProjectAs(callback?: ProjectResponseCallback) {
        this.call('save-project-as', null, projectResponseCallback(callback));
    }

    /**
     * Save the project and download the project file content.
     */
    downloadProject(ref?: string|null, callback?: FileContentCallback) {
        this.call('download-project', (ref) ? ref: null, callback);
    }

    /**
     * Import attributes from some data and an import type key (see api.py:_attr_importers).
     */
    importAttr(key: string, data: string, callback?: ProjectResponseCallback, errorCallback?: ErrorCallback) {
        this.compress(data, (compressedData) => {
            this.call('import-attr', [key, compressedData], projectResponseCallback(callback), errorCallback);
        });
    }

    /**
     * Import a design study from some data and an import type key (see api.py:_design_study_importers).
     * Filename is expected without extension.
     */
    importDesignStudy(key: string, data: string, fileName: string, callback?: ProjectResponseCallback,
                      errorCallback?: ErrorCallback) {
        this.compress(data, (compressedData) => {
            this.call('import-ds', [key, compressedData, fileName], projectResponseCallback(callback), errorCallback);
        });
    }

    /**
     * Export a design study to some data format (see api.py:_design_study_exporters).
     */
    exportDesignStudy(key: string, dsId: m.idType, callback?: FileContentCallback) {
        this.call('export-ds', [key, dsId], callback);
    }

    /**
     * Copy a design study.
     */
    copyDesignStudy(dsId: m.idType, callback?: ProjectResponseCallback) {
        this.call('copy-ds', dsId, projectResponseCallback(callback));
    }

    /**
     * Load design points for a given design study. Design studies sent by other means are sent without their design
     * points to reduce the amount of data to transfer.
     */
    loadDesignStudyPoints(dsId: m.idType, callback?: (designPoints: m.DesignPoint[]) => void) {
        this.call('load-ds-points', dsId, (json: string) => {
            if (callback) {
                const ds: m.DesignStudy = parse(json);
                callback(ds.designPoints);
            }
        });
    }

    /**
     * Get a list of available attribute sets to import.
     */
    getAttrSets(callback?: (sets: string[]) => void) {
        this.call('get-attr-sets', null, callback);
    }

    /**
     * Import an attribute set.
     */
    loadAttrSet(idx: number, callback?: ProjectResponseCallback, errorCallback?: ErrorCallback) {
        this.call('load-attr-set', idx, projectResponseCallback(callback), errorCallback);
    }

    /**
     * Get a list of available design studies to import.
     */
    getDesignStudySets(callback?: (sets: string[]) => void) {
        this.call('get-ds-sets', null, callback);
    }

    /**
     * Import a design study.
     */
    loadDesignStudy(idx: number, callback?: ProjectResponseCallback, errorCallback?: ErrorCallback) {
        this.call('load-ds', idx, projectResponseCallback(callback), errorCallback);
    }

    /**
     * Undo the previous action.
     */
    undo(callback?: ProjectResponseCallback) {
        this.call('undo', null, projectResponseCallback(callback));
    }

    /**
     * Redo the next action.
     */
    redo(callback?: ProjectResponseCallback) {
        this.call('redo', null, projectResponseCallback(callback));
    }

    /**
     * Helper function for calling an API endpoint. See api.py for available endpoints.
     */
    call(target: string, args?: any, callback?: Function, errorCallback?: ErrorCallback,
         suppressErrorDisplay: boolean = false) {

        if (isUndefined(args)) args = null;
        this.startLoading(target);

        this.socket.emit(target, args, (d: ReturnData) => {
            // Check for formatting
            if (typeof d === 'undefined' || !d.hasOwnProperty('success')) {
                console.log('Malformed response (forgot to use success() or error() in api.py?)', target, args, d);
                this.error('Malformed response from '+target);
                this.finishLoading(target);
                return;
            }

            dispatcher.setAuthStatus(d.auth, d.scope);

            // Check success
            if (d.success) {
                if (d.msg) this.success(d.msg);

                if (callback) {
                    if (d.comp) {
                        this.decompress(callback, d.data);
                    } else {
                        callback(d.data);
                    }
                }

            } else {
                console.log('Error', target, d.msg);

                if (d.msg && !suppressErrorDisplay) this.error(d.msg);

                if (errorCallback) errorCallback(d);
            }
            this.finishLoading(target);
        });
    }

    success(msg: string) { dispatcher.success(msg); }
    error(msg: string) { dispatcher.error(msg); }

    compress(data: string, callback: (compressedData: string) => void) {
        const arrayBuffer = pako.gzip(data, { level: 9 });
        const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
        base64Encode(blob, (compressedData) => {
            // console.log(`Deflated ${new Blob([data]).size} to ${new Blob([compressedData]).size} bytes`);
            callback('C_'+compressedData);
        });
    }

    decompress(callback: Function, data: string) {
        const blob = b64ToBlob(data, 'application/octet-stream');
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            const arrayBuffer = event.target?.result as Uint8Array;
            const jsonString = pako.inflate(arrayBuffer, { to: 'string' });
            // console.log(`Inflated ${new Blob([data]).size} to ${new Blob([jsonString]).size} bytes`);
            const jsonData = parse(jsonString, undefined, true);
            callback(jsonData);
        };
        fileReader.readAsArrayBuffer(blob);
    }

    callKeepalive() {
        this.call('keepalive');
    }
    startKeepalive(timeout: number) {
        const that = this;
        setInterval(() => that.callKeepalive(), timeout);
    }

    startLoading(event: string) {
        this.loadingEvents.push([event, new Date()]);
        debouncedLoading(true);
    }
    finishLoading(event: string) {
        let iFound: number = -1;
        for (let i = 0; i < this.loadingEvents.length; i++) {
            if (this.loadingEvents[i][0] == event) iFound = i;
        }
        if (iFound !== null) this.loadingEvents.splice(iFound, 1);

        this.checkFinishedLoading();
    }
    checkFinishedLoading() {
        const timeout = this.loadingTimeout;
        // https://github.com/Microsoft/TypeScript/issues/5710#issuecomment-157886246
        this.loadingEvents = filter(this.loadingEvents, (evt) => (+(new Date()) - +evt[1])/1000 < timeout);

        if (this.loadingEvents.length == 0) {
            debouncedLoading.cancel();
            dispatcher.isLoading(false);
        }
    }
}
