<template>
    <div class="fill-height d-flex flex-column">
        <div class="d-flex pb-2 pt-4 align-center justify-center flex-grow-0">
            <div class="flex-grow-0" style="width: 300px">
                <v-select
                    v-model="xDataIdx"
                    :items="dataSourceItems"
                    label="X axis"
                />
            </div>
            <div class="flex-grow-0 ml-4" style="width: 300px">
                <v-select
                    v-model="yDataIdx"
                    :items="dataSourceItems"
                    label="Y axis"
                />
            </div>
        </div>

        <div class="flex-grow-1">
            <plotly
                :data="plotData"
                :layout="layout"
                style="height: 100%;"
            />
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import {store} from "@/store";
    import {linkedAttr, linkedDesignPointAttributes} from "./ds";

    import map from 'lodash/map';
    import filter from 'lodash/filter';
    import concat from 'lodash/concat';
    import sortBy from 'lodash/sortBy';
    import values from 'lodash/values';
    import forEach from 'lodash/forEach';
    import includes from 'lodash/includes';
    import fromPairs from 'lodash/fromPairs';

    import plotly from './plotly/plotly.vue';
    import {Font} from "plotly.js";

    export default Vue.extend({
        name: "plotting",
        components: {
            plotly,
        },
        props: {
            designStudyIds: {required: true},
            loading: {default: false},
        },
        data: () => ({
            xDataIdx: 2,
            yDataIdx: 0,
        }),
        computed: {
            project: () => store.state.localProject,
            designStudies() {
                const ids = this.designStudyIds;
                return filter(this.project.designStudies, (ds) => includes(ids, ds.id));
            },
            hasMultiple() {
                return this.designStudies.length > 1;
            },
            designStudyAttr() {
                return map(this.designStudies, (ds) => linkedAttr(ds));
            },
            designStudyPoints() {
                const dp = store.state.designPoints;
                return map(this.designStudies, (ds) => linkedDesignPointAttributes(ds, dp[ds.id] || []));
            },
            dpNames() {
                const dsPoints = this.designStudyPoints;
                const hasMultiple = this.hasMultiple;
                return map(this.designStudies, (ds, idx) => {
                    return map(dsPoints[idx], (dp, dpIdx) => {
                        let name = `#${dpIdx+1}`
                        if (dp.ref) name += ` (${dp.ref})`;
                        if (hasMultiple) name = `${ds.name}: ${name}`;
                        return name;
                    });
                });
            },
            dpRef() {
                const dsPoints = this.designStudyPoints;
                const hasMultiple = this.hasMultiple;
                return map(this.designStudies, (ds, idx) => {
                    return map(dsPoints[idx], (dp, dpIdx) => {
                        return `#${dpIdx+1}`;
                    });
                });
            },
            projectAttrMap() {
                return fromPairs(map(this.project.attributes, (attr) => [attr.id, attr]));
            },
            projectRefAttrData() {
                const attrMap = this.projectAttrMap;
                const attrIds = new Set();
                const attrs = {};
                forEach(this.designStudyAttr, (dsAttrs, i) => {
                    forEach(dsAttrs, (dsAttr, j) => {

                        if (attrIds.has(dsAttr.attributeId)) {
                            attrs[dsAttr.attributeId].dsIdx[i] = j;

                        } else if (dsAttr.attributeId in attrMap) {
                            attrIds.add(dsAttr.attributeId);
                            const attr = attrMap[dsAttr.attributeId];
                            attrs[attr.id] = {
                                attr,
                                dsIdx: {[i]: j},
                            };
                        }
                    });
                });
                return sortBy(values(attrs), 'name');
            },
            dataSources() {
                const dps = this.designStudyPoints;
                const projectAttrData = this.projectRefAttrData;
                return concat(
                    [{
                        name: 'Value',
                        values: map(dps, (points) => map(points, (dp) => dp.value || 0)),
                        range: [0, 1],
                    }, {
                        name: 'Sample #',
                        values: map(dps, (points) => map(points, (_, idx) => idx)),
                    }],
                        map(projectAttrData, (attrData) => ({
                            name: attrData.attr.name+' (content)',
                            values: map(dps, (points, i) => {
                                const attrIdx = attrData.dsIdx[i];
                                if (attrIdx === undefined) return null;
                                return map(points, (dp) => dp.attributes[attrIdx].content);
                            }),
                        })),
                        map(projectAttrData, (attrData) => ({
                            name: attrData.attr.name+' (utility)',
                            values: map(dps, (points, i) => {
                                const attrIdx = attrData.dsIdx[i];
                                if (attrIdx === undefined) return null;
                                return map(points, (dp) => dp.attributes[attrIdx].utility);
                            }),
                            range: [0, 1],
                        })),
                );
            },
            dataSourceItems() {
                return map(this.dataSources, (ds, idx) => ({ value: idx, text: ds.name }));
            },
            plotData() {
                const dpNames = this.dpNames;
                const dataSources = this.dataSources;
                const xDataIdx = this.xDataIdx;
                const yDataIdx = this.yDataIdx;
                const dpRef = this.dpRef

                const data = [];
                forEach(this.designStudies, (ds, i) => {
                    const x = dataSources[xDataIdx].values[i];
                    const y = dataSources[yDataIdx].values[i];
                    if (!x || !y) return;

                    data.push({
                        x, y,
                        name: ds.name,
                        text: dpRef[i],
                        mode: 'markers+text',
                        marker: {size: 9},
                        type: 'scatter',
                        textfont : {family:'Times New Roman'},
                        textposition: 'top center',
                    });
                });
                return data;
            },
            layout() {
                const dataSources = this.dataSources;
                const xDataIdx = this.xDataIdx;
                const yDataIdx = this.yDataIdx;
                return {
                    xaxis: {
                        title: { text: dataSources[xDataIdx].name, },
                        range: dataSources[xDataIdx].range,
                    },
                    yaxis: {
                        title: { text: dataSources[yDataIdx].name, },
                        range: dataSources[yDataIdx].range,
                    },
                };
            },
        },
    });
</script>

<style scoped>

</style>