<template>
    <div class="fill-height">
        <div class="fill-height d-flex align-stretch">
            <v-container fluid>
                <div class="col-12 col-md-9 pa-0 scroll">
                    <h1>Design Study Settings</h1>

                    <v-form v-model="formValid" ref="form" @submit.prevent="">
                        <!-- Name field -->
                        <v-text-field
                            v-model="editedItem.name"
                            :disabled="!canEdit"
                            label="Name"
                            @blur="_updateDesignStudy"
                            @keyup.enter.stop="_updateDesignStudy"
                            :rules="[rules.required]"
                        ></v-text-field>

                        <!-- Ref field -->
                        <v-text-field
                            v-model="editedItem.ref"
                            :disabled="!canEditRef"
                            label="External reference"
                            hide-details="auto"
                            @blur="_updateDesignStudy"
                            @keyup.enter.prevent="_updateDesignStudy"
                        ></v-text-field>
                    </v-form>

                    <h3 class="mt-10">Design Study Attributes
                        <span v-if="!allLinked" class="text-subtitle-1 orange--text">(not all attributes linked)</span>
                    </h3>
                    <v-simple-table dense>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Project Attribute</th>
                                <th>Weight</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, idx) in items" :key="idx">
                                <td>{{ idx+1 }}</td>
                                <td>{{ item.name }}</td>
                                <td>
                                    <v-select
                                        :items="projectAttrItems"
                                        :value="item.attrId"
                                        @input="_setAttrId(idx, $event)"
                                    />
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="item.weight"
                                        @blur="_setAttrWeight(idx, item.weight)"
                                        :placeholder="item.projectAttrWeight || 'N/A'"
                                        :rules="[rules.float]"
                                    >
                                        <template v-slot:append-outer>
                                            ({{ item.relWeight }}%)
                                        </template>
                                    </v-text-field>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-container>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import {store} from "@/store";
    import {dispatcher} from "@/main";
    import {fmt} from "@/comp/utility-curve";
    import {editDesignStudy, editDesignStudyAttribute, getDesignPointAttrWeight} from "@/model/ops";
    import {allLinked, linkedAttr} from "./ds";

    import map from 'lodash/map';
    import concat from 'lodash/concat';
    import fromPairs from 'lodash/fromPairs';
    import cloneDeep from 'lodash/cloneDeep';
    import sum from "lodash/sum";

    export default Vue.extend({
        name: "settings",
        props: {
            designStudy: {required: true},
        },
        data: () => ({
            formValid: false,
            editedItem: {},

            rules: {
                required: (value) => !!value || 'Required',
                float: (value) => !value || !isNaN(parseFloat(value)) || 'Must be a numeric value',
            },
        }),
        watch: {
            designStudy: {
                handler() {
                    this._updatedValue();
                },
                deep: true,
            },
        },
        computed: {
            project: () => store.state.localProject,
            editable: () => store.state.editable,
            canEdit() {
                return this.editable && store.state.settings.edit_ds;
            },
            canEditRef() {
                return this.editable && store.state.settings.edit_refs;
            },
            designStudyId() {
                return this.designStudy.id;
            },

            projectAttr() {
                return this.project.attributes;
            },
            projectAttrItems() {
                return concat([{ value: null, text: '' }],
                    map(this.projectAttr, (attr) => ({ value: attr.id, text: attr.name })));
            },
            projectAttrMap() {
                return fromPairs(map(this.projectAttr, (attr) => [attr.id, attr]));
            },
            attr() {
                return this.designStudy.attributes;
            },
            linkedAttr() {
                return linkedAttr(this.designStudy);
            },
            attrWeights() {
                const projectAttrMap = this.projectAttrMap;
                return map(this.linkedAttr, (attr) => {
                    const projectAttr = (attr.attributeId in projectAttrMap) ? projectAttrMap[attr.attributeId]: null;
                    return getDesignPointAttrWeight(attr, projectAttr);
                });
            },
            totalWeight() {
                return sum(this.attrWeights);
            },
            allLinked() {
                return allLinked(this.designStudy);
            },
            items() {
                const projectAttrMap = this.projectAttrMap;
                const totalWeight = this.totalWeight;

                return map(this.attr, (attr) => {
                    const projectAttr = (attr.attributeId in projectAttrMap) ? projectAttrMap[attr.attributeId]: null;
                    const weight = getDesignPointAttrWeight(attr, projectAttr);

                    return {
                        name: attr.name,
                        attrId: attr.attributeId,
                        weight: attr.weight,
                        projectAttrWeight: (projectAttr) ? projectAttr.weight.toString(): null,
                        relWeight: fmt((totalWeight === 0) ? 0: 100*(weight/totalWeight), 1),
                    };
                });
            },
        },
        methods: {
            _updatedValue() {
                if (this.$refs.form) this.$refs.form.resetValidation();

                this.editedItem = cloneDeep(this.designStudy);
            },
            _updateDesignStudy() {
                this.$refs.form.validate();
                this.$nextTick(() => {
                    if (!this.$refs.form.validate()) return;
                    if (!this.formValid) return;

                    const item = this.editedItem;
                    editDesignStudy(this.designStudyId, (ds) => {
                        ds.name = item.name;
                        if (this.canEditRef) ds.ref = item.ref;
                    });
                });
            },
            _setAttrId(idx, projAttrId) {
                editDesignStudyAttribute(this.designStudyId, idx, (attr) => {
                    attr.attributeId = projAttrId;
                });
            },
            _setAttrWeight(idx, weight) {
                if (!weight) {
                    weight = null;
                } else {
                    weight = parseFloat(weight);
                    if (isNaN(weight)) return;
                }

                editDesignStudyAttribute(this.designStudyId, idx, (attr) => {
                    attr.weight = weight;
                });
            },
        },
        created() {
            dispatcher.onFileOps(this._updatedValue);
            dispatcher.onUndoRedo(this._updatedValue);
        },
        mounted() {
            this._updatedValue();
        },
    });
</script>

<style scoped>

</style>