<template>
    <v-dialog
        v-model="showing"
        scrollable
        max-width="800"
    >
        <v-card :loading="loading">
            <v-card-title class="headline">Project List</v-card-title>

            <v-card-text class="pa-0">
                <v-data-table
                        :headers="headers"
                        :items="projectItems"
                        :search="search"
                        must-sort
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        disable-pagination
                        hide-default-footer
                        @click:row="_clickRow"
                        class="data-table data-table-pointer"
                >
                    <template v-slot:item.actions="{ item }">
                        <v-icon small v-if="hasLoadProject" @click.stop="_selectProject(item.ref)"
                                title="Load project">{{ mdiPencil }}</v-icon>
                        <v-icon small v-if="hasDeleteProject" @click.stop="_askDelete(item.ref)"
                                title="Delete project">{{ mdiDelete }}</v-icon>
                        <v-icon small v-if="hasExportProject" @click.stop="_exportProject(item.ref)"
                                title="Export project">{{ mdiDownload }}</v-icon>
                    </template>
                    <template v-slot:item.created="{ item }">{{ item.createdText }}</template>
                    <template v-slot:item.updated="{ item }">{{ item.updatedText }}</template>
                </v-data-table>
            </v-card-text>

            <v-card-actions v-if="hasNewProject || hasImportProject">
                <v-btn text color="primary" @click="_newProject" :disabled="loading"
                       v-if="hasNewProject">New Project<v-icon right>{{ mdiFileOutline }}</v-icon></v-btn>
                <v-btn text color="primary" @click="_importProject" :disabled="loading"
                       v-if="hasImportProject">Import Project<v-icon right>{{ mdiUpload }}</v-icon></v-btn>
            </v-card-actions>
        </v-card>

        <!-- Confirm delete dialog -->
        <v-dialog
                v-model="confirmDeleteDialog"
                max-width="350"
        >
            <v-card>
                <v-card-title class="headline">Delete the project?</v-card-title>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="primary" @click="confirmDeleteDialog = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="_deleteConfirmed">Delete Project</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
    import {api, dispatcher} from "@/main";
    import {store, updatedProject} from '@/store';
    import {mdiDelete, mdiPencil, mdiUpload, mdiDownload, mdiFileOutline} from '@mdi/js';
    import {openFile, saveFileContentCallback} from "@/files";

    import map from 'lodash/map';
    import parseISO from 'date-fns/parseISO';
    import format from 'date-fns/format';
    import Vue from "vue";

    export default Vue.extend({
        name: "project-list",
        data: () => ({
            mdiDelete, mdiPencil, mdiUpload, mdiDownload, mdiFileOutline,

            loading: false,
            showing: false,
            confirmDeleteDialog: false,
            deleteRef: null,

            search: '',
            sortBy: 'updated',
            sortDesc: true,
            projects: [],
            headers: [
                { value: 'name', text: 'Name' },
                { value: 'created', text: 'Created' },
                { value: 'updated', text: 'Last Modified' },
                { value: 'actions', text: 'Actions', sortable: false, },
            ],
        }),
        computed: {
            hasNewProject: () => store.state.settings.new_project,
            hasImportProject: () => store.state.settings.import_project,
            hasLoadProject: () => store.state.settings.load_project,
            hasDeleteProject: () => store.state.settings.delete_project,
            hasExportProject: () => true,

            projectItems() {
                const dateFormat = 'dd-MM-yyyy HH:mm';
                return map(this.projects, (project) => {
                    const createdText = (project.created) ? format(parseISO(project.created), dateFormat): '';
                    const updatedText = (project.updated) ? format(parseISO(project.updated), dateFormat): '';

                    return {
                        ref: project.ref,
                        name: project.name,
                        created: project.created || '',
                        updated: project.updated || '',
                        createdText,
                        updatedText,
                    };
                });
            },
        },
        methods: {
            show() {
                this._updateList();
                this.showing = true;
            },
            _updateList() {
                this.loading = true;
                api.listProjects((projects) => {
                    this.projects = projects;
                    this.loading = false;
                });
            },
            close() {
                this.showing = false;
            },

            _newProject() {
                if (!this.hasNewProject) return;

                this.close();
                this.$emit('new');
            },
            _importProject() {
                if (!this.hasImportProject) return;

                openFile((data) => {
                    this.loading = true;
                    api.importProject(data, (projects => {
                        this.projects = projects;
                        this.loading = false;
                    }));
                });
            },
            _clickRow(e, data) {
                this._selectProject(data.item.ref);
            },
            _selectProject(projectRef) {
                if (!this.hasLoadProject) return;

                this.loading = true;
                api.selectProject(projectRef, (project, response) => {
                    updatedProject(project, response);
                    dispatcher.fileOps();
                    this.close();
                });
            },
            _exportProject(projectRef) {
                if (!this.hasExportProject) return;
                api.downloadProject(projectRef, saveFileContentCallback);
            },
            _askDelete(projectRef) {
                if (!this.hasDeleteProject) return;

                this.deleteRef = projectRef;
                this.confirmDeleteDialog = true;
            },
            _deleteConfirmed() {
                this.loading = true;
                this.confirmDeleteDialog = false;
                api.deleteProject(this.deleteRef, (project, response) => {
                    updatedProject(project, response);
                    dispatcher.fileOps();

                    this._updateList();
                });
            },
        },
    });
</script>

<style scoped>

</style>