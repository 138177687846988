import * as m from "@/model/model";

import map from "lodash/map";
import clone from "lodash/clone";
import every from "lodash/every";
import filter from "lodash/filter";
import assign from "lodash/assign";

export function allLinked(ds: m.DesignStudy): boolean {
    return every(map(ds.attributes, isLinked));
}

export function linkedAttr(ds: m.DesignStudy): m.DesignPointAttribute[] {
    return filter(ds.attributes, isLinked);
}

export function isLinked(attr: m.DesignPointAttribute): boolean {
    return !!attr.attributeId;
}

export function linkedDesignPointAttributes(ds: m.DesignStudy, dps: m.DesignPoint[]): m.DesignPoint[] {
    const attrIsLinked = map(ds.attributes, isLinked);
    return map(dps, (dp: m.DesignPoint) => (assign(clone(dp), {
        attributes: filter(dp.attributes, (_, idx) => attrIsLinked[idx]),
    }) as m.DesignPoint));
}
